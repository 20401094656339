.AdminPostSalesBody .filter-btn {
    margin-top: 55px !important;
}
.AdminPostSalesBody .download-keys {
    align-items: center;
}
.AdminPostSalesBody .icon{
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 5px;
}
.AdminPostSalesBody .color-green-color svg{
font-size: 20px !important;
}
.AdminPostSalesBody .belowBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@media all and (max-width: 567.98px) { /*Edited by sankar*/
    .AdminPostSalesBody .belowBtn{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.AdminPostSalesBody .download-keys.right{
    gap: 30px;
}

@media all and (max-width: 991.98px) { /*Edited by sankar*/
    .AdminSettingPopup .post-sale-admin-block {
        width: auto !important;
  
    }
    .AdminSettingPopup .many-block-with-label-span-post-sale-admin.left .post-sale-admin-block {
        width: 100% !important;
    }
}
.AdminSettingPopup .post-sale-admin-block div:first-child label {
    font-size: 14px;
    color: #000;
    text-transform: capitalize;
}
.AdminSettingPopup .gridBox{
    max-width: none !important;
}
.AdminSettingPopup .many-block-with-label-span-post-sale-admin{
    justify-content: space-around;
    column-gap: 20px;
}
.AdminSettingPopup .color-orange-bg {
    background: #FFC989 !important;
    -webkit-backdrop-filter: blur(33.3123893737793px);
    backdrop-filter: blur(33.3123893737793px);
}
.AdminSettingPopup .many-block-with-label-span-post-sale-admin.left{
    justify-content:space-between !important;
    column-gap: 20px;

}
.AdminSettingPopup .many-block-with-label-span-post-sale-admin.left .post-sale-admin-block {
    width: 23%
}
.AdminSettingPopup .post-sale-admin-block div:first-child label.headline {
    font-size: 11px;
    color: #000;
    text-transform: capitalize;
}
.AdminSettingPopup .color-green-bg {
    background: #00E55B !important;
    -webkit-backdrop-filter: blur(33.3123893737793px);
    backdrop-filter: blur(33.3123893737793px);
}
.AdminSettingPopup.reopen{
    height:80%;
    overflow-y: scroll;
}
.AdminSettingPopup.reopen .listOne {
    justify-content: center !important;
    gap: 30%;
}
.AdminSettingPopup.reopen .applyAll{
    border-radius: 4.519px;
    border: 2.259px solid #F00;
    background: linear-gradient(134deg, #F00 -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    padding: 7px 30px !important;
}
.AdminSettingPopup.reopen .post-sale-admin-block {
    border-radius: 4px !important;
}
.AdminSettingPopup.reopen .post-sale-admin-block div:nth-child(2) {
    height: 30px !important;
}
.AdminSettingPopup.reopen.applyAll{
    height:55%;
}
.AdminSettingPopup.reopen.applyAll.thumb{
    height:74%;
}
.AdminSettingPopup.reopen.applyAll .alertInfo{
    color: #EF0505;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: capitalize !important;
    max-width: 634px;
}
.AdminSettingPopup.reopen.applyAll .alertInfo.space{
    margin-bottom: 50px;
}
.AdminSettingPopup.reopen.applyAll  .wholeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap: 30px;
}

.thumb .textCenter{
    text-align: center !important;
}
.thumb .Info{
margin-top: 20px;
}
.AdminSettingPopup.reopen.applyAll.thumb.cancel .alertInfo.cancel{
   max-width: 842px !important;
   margin: auto;
    text-transform: lowercase !important;
}
.reason.select{
    text-align: left;
    margin: 30px 0px;
}

.AdminSettingPopup.reopen.applyAll.thumb.cancel .warn {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    row-gap: 30px;
}
.AdminSettingPopup.reopen.applyAll.thumb.cancel.confirm .alertInfo {
    color: #05FF00;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: lowercase !important;
    max-width: none !important;

}
.AdminSettingPopup.reopen.applyAll.thumb.cancel.confirm .reason.select{
  margin-left: 23%;
}
.AdminSettingPopup.reopen.applyAll.thumb.cancel.confirm .listThree {
    gap: 20% !important;
    justify-content: center;
}

/*Edited by sankar*/

img.img-fluid {
    width: 200px;
}
