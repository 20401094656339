.extented-warrenty {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.warrenty-body {
    width: 80% !important;
    flex-shrink: 0;
    border-radius: 23.821px;
    background: #DFE5F4;
    padding-bottom: 20px;
}


.change-owner input::placeholder {
    text-align: left !important;
}

.watch-div {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin: auto !important;
    width: 40% !important;
}

.watch-id {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0px;
    align-items: center;
    gap: 30px;
}


.map-component {
    width: 80% !important;
    height: 400px;
    flex-shrink: 0;
}

.map-component iframe {
    width: -webkit-fill-available;
    border: none;
    height: inherit;

}

.Guarantee {
    width: 60% !important;
    height: 73.717px;
    border-radius: 13.164px;
    border: 1.316px solid #000;
    background: rgba(18, 237, 53, 0.20);
    color: #000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    padding: 20px;
}

.warrenty-records {
    width: 275.123px;
    height: 25.011px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;

}

.warrenty-trns {
    width: 752px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.trns-details {
    width: 43% !important;
    padding: 15px;
}

.warrenty-display {
    display: flex;
    align-items: center;
    gap: 10px;
}

.reference-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-div div {
    width: 30px;
    height: 30px;
    font-size: 15px;
}

.ref-text {
    color: #0070F0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}

.ref-text2 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.trns-body {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.warrenty-hr {
    width: 400.838px;
    height: 0px;
    stroke-width: 1.053px;
    color: #0070F0;

}

.user-logo {
    width: 96.096px;
    height: 17.113px;
    color: #000;
    text-align: center;
    font-size: 15.332px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    gap: 5px;
}

.warrenty-records1 {
    display: flex;
    width: 489.846px;
    flex-direction: column;
    justify-content: flex-end;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 30px;
    line-height: normal;

}

.br {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-color: #7491d4;
}

.para-warrenty {
    color: #000;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.para-warrenty span {
    color: #FC0C0C;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.warrenty-main-display {
    width: 420.136px;
    display: flex;
    gap: 20px;
    align-items: center;

}


.for-only-65 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.for-only-65 div {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
}

.for-only-65 span {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    line-height: normal;
}

.Buy-now-warrenty {
    justify-content: center !important;
    background: rgb(52, 237, 52) !important;
    color: #FFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 10px 40px !important;
    text-transform: capitalize;
}

.go-green {
    color: #58A626 !important;
}

.user-logo2 span {
    width: auto;
    color: #000;
    font-family: Roboto;
    font-size: 21.852px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.user-logo2 {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 130px;
}

.warrenty-records3 {
    width: 311.339px;
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}

.warrenty-records4 {
    width: 438.046px;
    justify-content: flex-end;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.warrenty-records4 span {
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.warrenty-close-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.warrenty-close-div2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 90%;
    margin-top: 30px;
}

.ownership-btn {
    width: 230.45px;
    border-radius: 6.119px;
    border: 3.059px solid #E42B15;
    background: linear-gradient(134deg, #FF1B00 -26.79%, #756563 230.75%);
    color: #FFF !important;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.ownership-btn img {
    padding-right: 10px;
}

.owner-text {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.smith {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.owner-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.new-owner {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

input#outlined-basic1 {
    width: 347.764px;
    /* height: 43.899px; */
    flex-shrink: 0;
    border-radius: 4.43px;
    background: #FFF;
    box-shadow: 3.32245px 3.32245px 7.75238px 0px rgba(156, 156, 156, 0.48) inset, -3.32245px -3.32245px 7.75238px 0px #FFF inset;
}

.Guarantee3 {
    width: 466.114px;
    height: 143.862px;
    border-radius: 7.193px;
    border: 1.439px solid #7E0000;
    background: rgba(255, 27, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
}

.securidy-code-text {
    width: 405.692px;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;

}

.proceed-btn {
    margin-top: 50px !important;
    width: 180.548px;
    border-radius: 6.501px;
    border: 3.25px solid #FFD600;
    background: linear-gradient(134deg, #FFB800 -26.79%, #8B8B8B 230.75%);
    color: #FFF !important;

}


@media (max-width:568px) {
    .warrenty-records {
        font-size: 15px;
    }

    .Guarantee {
        font-size: 14px;
    }

    /* .Buy-now-warrenty{
        font-size: 10px !important;
    } */
    .watch-id {
        font-size: 14px !important;
        margin: 10px auto !important;
    }

    .warrenty-records1 {
        font-size: 15px;
    }

    .para-warrenty,
    .para-warrenty span {
        font-size: 15px;
    }

    .for-only-65,
    .for-only-65 span {
        font-size: 18px;
    }

    .user-logo2 span {
        font-size: 14px;
    }

    .Buy-now-warrenty {
        font-size: 10px;
    }

    input#outlined-basic1 {
        width: auto;
    }

    .proceed2-btn a button {
        width: 210px;
        font-size: 12px;
    }

    .ownership-btn img {
        width: 36px;
    }

    .purchase-text {
        width: 90%;
    }

    .warrenty-records4,
    .warrenty-records4 span,
    .purchase-text {
        font-size: 15px;
    }
}

@media(max-width:767.98px) {
    .warrenty-body {
        width: 100% !important;
    }

    .Guarantee {
        width: 80% !important;
    }


    .trns-body {
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 15px;
    }

    .warrenty-main-display {
        flex-direction: column;
        width: 100%;
    }

    .warrenty-records1 {
        width: auto !important;
    }

    .warrenty-records4,
    .warrenty-records3,
    .Guarantee3 {
        width: 90% !important;
    }
}




@media (min-width: 1px) and (max-width: 575.98px) {
    .ref-text {
        word-break: break-all;
    }

    .warrenty-display {
        flex-direction: column;
        align-items: center;
    }
}