.left-side-txt img {
  width: 220px;
}
.login-txt h6{
  margin: 15px 0px;
}
.login-txt h6 span{
  color: #0065FF;
}
.did-have-account {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  justify-content: center;
}

.did-have-account span,
a {
  font-size: 14px;
  color: #0065FF !important;
}

.logo-side {
  padding-left: 40px;
  margin-top: -70px !important;
}

.logo-side p {
  color: #151515;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 300px;
  margin: 0;
  padding-left: 55px;
  line-height: 1.5;
}

/* .right-content{
  margin-top: 70px !important;
} */
/* .right-content::after {
  content: "";
  position: absolute;
  top: 100px;
  right: 140px;
  width: 200px;
  height: 188px;
  background-image: url(../../img/diamond-form-dec.png);
  background-repeat: no-repeat;
  background-size: cover;
} */

.diamond-img {
  position: absolute;
  top: 0;
  right: 0;
}

.login-page {
  background-image: url(../../img/login-bg.png);
  padding: 0px 55px !important;
  position: relative;
  box-shadow: none !important;
  border-radius: 0px !important;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden !important;
}

.form-input {
  position: relative;
  border-radius: 15px;
  background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
  box-shadow: 0px 2px 0px 0px #FFF inset, 2px 8px 24px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  height: auto;
  max-width: 430px;
  border-top: 6px solid rgba(174, 192, 236, 0.34);
  border-bottom: 6px solid rgba(174, 192, 236, 0.34);
  border-width: 70px;
  margin: 0 auto;
  max-height: 400px;
  overflow-y: scroll;
}

.right-content {
  position: relative;
}

.form-input .login-txt h4 {
  margin: 0;
  color: #151515;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 7px;
}

.form-input .login-txt h1 {
  margin: 0;
  color: #151515;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
}

.cmn-input-cls .textField {
  margin: 0;
}

.login-contain {
  margin-top: -200px;
}

.form-input {
  position: relative;
}

.diamond-img img {
  width: 200px;
}

.diamond-img {
  position: absolute;
  top: -120px;
  right: -120px;
}

.cmn-input-cls .textField input::placeholder {
  color: #000 !important;
}

.cmn-input-cls .MuiInputBase-root {
  width: 400px;
  border-radius: 6.458px;
  background: #FFF;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
  height: 54px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.cmn-input-cls {
  margin-bottom: 20px;
}

.cmn-input-cls .textField fieldset {
  /* border: none !important; */
  border-color: #9c9c9c00 !important;
  color: #151515 !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
}

.cmn-input-cls .textField::placeholder {
  color: #151515 !important;
}

.crtPassword {
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
}

.crtPassword a {
  text-decoration: none !important;
  font-size: 16px;
  color: #0054B8;
  cursor: pointer;
}

Button.submit {
  border-radius: 6.2px;
  border: 3.1px solid #0065FF !important;
  background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%) !important;
  box-shadow: 0px 3.1px 0px 0px #FFF inset, 3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16) !important;
  color: #151515 !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  margin: auto;
  padding: 13px 30px;
  text-transform: capitalize !important;
  margin-top: 15px;
}

Button.meta {
  color: #151515 !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 800;
  line-height: 18px !important;
  border-radius: 4px;
  background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
  box-shadow: 0px 2px 0px 0px #fff inset, 2px 8px 24px 0px rgb(0 0 0 / 19%);
  margin: auto;
  display: flex;
  padding: 0px 11px;
}

.choice {
  display: flex;
  justify-content: center;
  margin: 23px auto 20px;
  color: #151515;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
}

/* .login-page.register {
  height: auto;
} */

@media(max-width: 767.98px) {
  .left-side-txt {
    text-align: center;
  }

  .login-page {
    background-image: url(../../img/login-bg-sm.png);
    padding: 20px 14px !important;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .right-content {
    margin-top: 50px !important;
  }

  .cmn-input-cls .MuiInputBase-root {
    width: 350px;
  }

  .form-input {
    width: 80%;
    /* padding-right: 0px !important; */
    margin: 0 auto;

  }

  .logo-side {
    padding-left: 0;
  }

  .logo-side p {
    max-width: 300px;
    margin: 0 auto;
    padding-left: 0;
    line-height: 1.5;
    text-align: center;
  }

  .diamond-img img {
    display: none;
  }

  .crtPassword a {
    font-size: 13px;
  }

  .form-input .login-txt h4 {
    font-size: 19px;
  }

  .form-input .login-txt h1 {
    font-size: 22px;
  }

  .cmn-input-cls .textField {
    margin-bottom: 10px !important;
    margin: 0;
  }

  .cmn-input-cls {
    margin-bottom: 5px;
  }

  .cmn-input-cls .textField fieldset {
    width: 93% !important;
  }

  .crtPassword {
    margin-left: 3px;
    margin-top: -7px !important;
  }

  Button.submit {
    font-size: 15px !important;
  }

  Button.meta {
    font-size: 13px !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .cmn-input-cls .MuiInputBase-root {
    width: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .login-page {
    background-image: url(../../img/login-bg-sm.png);
    padding: 20px 14px !important;
    height: 100%;
  }

  .crtPassword {
    font-size: 16px !important;
    margin-left: 5px !important;
  }

  .diamond-img img {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .form-input {
    width: 80%;
  }

  .crtPassword {
    margin-left: 7px;
  }

  .cmn-input-cls .textField input {
    width: 100%;
  }

  .cmn-input-cls .textField {
    width: auto;
  }

  .right-content::after {
    right: -35px;
  }

  .choice {
    margin: 10px auto 10px;
  }

  .login-page {
    background-image: url(../../img/login-bg-sm.png);
    padding: 20px 14px !important;
    height: 100vh;
  }
}


@media (max-width:1699.98px) {
  .logo-side {
    padding-left: 40px;
    margin-top: 0px !important;
  }
}

.login-txt {
    text-align: center;
}