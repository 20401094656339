.extented-warrenty {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.warrenty-body {
    width: 80% !important;
    flex-shrink: 0;
    border-radius: 23.821px;
    background: #DFE5F4;
}

.watch-default img {
    width: 60px;
}

/* .warrenty-body a img {
    margin-top: 34px;
    margin-left: 30px;
} */

.proceed2-btn a button {
    margin-top: 20px;
    width: auto;
}

.watch-div {
    display: flex;
    justify-content: center;
    margin-top: 25px;

}

.watch-div img {
    width: 110px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
}

.watch-id {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0px;
    align-items: center;
    gap: 30px;
}

.map-component {
    width: 467.315px;
    height: 400px;
    flex-shrink: 0;
}

.map-component iframe {
    width: -webkit-fill-available;
    border: none;
    height: inherit;
}

.Guarantee {
    width: 379.117px;
    height: 73.717px;
    border-radius: 13.164px;
    border: 1.316px solid #000;
    background: rgba(18, 237, 53, 0.20);
    color: #000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    padding: 20px;
}

.warrenty-records {
    width: 275.123px;
    height: 25.011px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.warrenty-trns {
    width: 752px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.trns-details {
    width: 447.569px;
    padding: 15px;
}

.warrenty-display {
    display: flex;
    gap: 10px;
}

.reference-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-div div {
    width: 30px;
    height: 30px;
    font-size: 15px;
}

.ref-text {
    color: #0070F0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}

.ref-text2 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.trns-body {
    display: flex;
    justify-content: space-between;
}

.warrenty-hr {
    width: 400.838px;
    height: 0px;
    stroke-width: 1.053px;
    color: #0070F0;

}

.user-logo {
    width: 96.096px;
    height: 17.113px;
    color: #000;
    text-align: center;
    font-size: 15.332px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    gap: 5px;
}

.warrenty-records1 {
    display: flex;
    width: 489.846px;
    flex-direction: column;
    justify-content: flex-end;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 30px;
    line-height: normal;

}

.br {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-color: #7491d4;
}

.para-warrenty {
    color: #000;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.para-warrenty span {
    color: #FC0C0C;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.warrenty-main-display {
    width: 420.136px;
    display: flex;
    gap: 20px;
    align-items: center;

}


.for-only-65 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.for-only-65 div {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
}

.for-only-65 span {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    line-height: normal;
}

.Buy-now-warrenty {
    justify-content: center !important;
    background: rgb(52, 237, 52) !important;
    color: #FFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 10px 40px !important;
    text-transform: capitalize;
}

.go-green {
    color: #58A626 !important;
}

.user-logo2 span {
    width: auto;
    color: #000;
    font-family: Roboto;
    font-size: 21.852px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.user-logo2 {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 200px;
}

.warrenty-records3 {
    width: 311.339px;
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}

.warrenty-records4 {
    width: 438.046px;
    justify-content: flex-end;
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
}

.warrenty-records4 span {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.warrenty-close-div {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
}


/* PurchseSuccess */


.purchase-text {
    display: flex;
    width: 410.397px;
    flex-direction: column;
    justify-content: flex-end;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

@media(max-width:767.98px) {
    .warrenty-body {
        width: 100% !important;
    }

    .Guarantee {
        width: 80% !important;
    }

    .trns-body {
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 15px;
    }

    .watch-description {
        width: 80% !important;
        text-align: justify;
    }

    .map-component {
        width: 90% !important;
    }

    .warrenty-hr,
    .trns-details,
    .warrenty-trns {
        width: 90% !important;
        padding: 5px !important;
    }

    .purch-sucess {
        padding-top: 50px !important;
        padding-bottom: 200px !important;
    }
}

.map-component.genral1 {
    background: transparent !important;
    border: none !important;
    border-radius: 0px !important;
}

.map-component.genral1 .leaflet-container {
    height: 300px;
    z-index: 1;
    margin-top: 30px;
}



.purch-sucess {
    padding: 150px 0;
    text-align: center;
}

.purch-sucess .logo {
    padding-bottom: 60px;
}

.purch-sucess p {
    font-size: 18px;
    margin: 0;
    margin-bottom: 30px;
    word-break: break-all;
}