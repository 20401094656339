.head-topic {
    font-size: 13px !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
}

.row-topic {
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: left !important;
}

.modelTypo {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.billing-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0px 15px;
}

div#earnings-sales table tbody tr td {
    text-align: center !important;
}

@media (max-width:991.98px) {
    .billing-btn-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin: 0px 15px;
    }
}

.quantity-bg {
    border-radius: 15px;
    background: rgba(23, 85, 231, 0.30) !important;
}

/* .total-pdts.quantity-bg p,
.total-pdts.quantity-bg .total-pdts-div-left span,
.total-pdts.quantity-bg .total-pdts-div-left label {
    color: #000 !important;
} */

.years-part {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.years-part p {
    font-size: 15.464px;
    font-style: normal;
    font-weight: 500;
    display: flex;
}

.pagination-secyion {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.five-block-divs-two-rows-top-inner-five-blk-inner-content.span-bg span {
    color: #219653;
    padding: 2px;
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
    border-radius: 61.233px;
    background: rgba(33, 150, 83, 0.20);
}

button.filter-btn {
    border-radius: 5.313px;
    border: 2.656px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
    color: #fff;
}

@media all and (max-width: 991.98px) {

    /*Edited by Sankar */
    .popBOX.BlockPopup.filterpopup {
        backdrop-filter: none !important;
        width: 540px !important;
    }
}


.popBOX.BlockPopup.cancel-invoice {
    backdrop-filter: none !important;
}

p.new-invoice {
    font-size: 20px;
    font-weight: 700;
}

.invoice-create {
    text-align: center;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.field-txt-box {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.max-size {
    width: 300px;
}


button.create-green-btn {
    border-radius: 4.519px;
    border: 2.259px solid #00FF29;
    background: linear-gradient(134deg, #0F6 -26.79%, #8B8B8B 230.75%);
    color: #fff;
    padding: 5px 22px;
}

button.cancel-green-btn {
    border-radius: 4.519px;
    border: 2.259px solid #F00;
    background: linear-gradient(134deg, #F00 -26.79%, #8B8B8B 230.75%);
    color: #fff;
    padding: 5px 22px;
}
.cancel-red-btn {
    border-radius: 4.519px;
    border: 2.259px solid #F00;
    background: linear-gradient(134deg, #F00 -26.79%, #8B8B8B 230.75%);
    color: #fff;
    padding: 5px 22px;
}

.create-cancel-btn {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: baseline;
}
.create-cancel-btns {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: baseline;
}

.start-end-date.start-end-date-admin.invoice-date .MuiStack-root {
    justify-content: flex-start !important;
}

.popBOX.BlockPopup.cancel-invoice {
    width: 800px !important;
    height: 56%;
}

.check-box-align {
    text-align: left;
    max-width: 448px !important;
    margin: 0 auto;
}

.create-cancel-btn.invoice-reject {
    margin-top: 51px;
    gap: 50px !important;
}

.file-upload {
    background: #fff !important;
}

.icon-space button {
    padding: 0px !important;
    min-width: 40px;
}

.upload-box {
    color: #000 !important;
    display: flex !important;
    padding: 80px !important;
    font-size: 20px !important;
    border: 3px dotted gray !important;
}

p.paid-txt {
    font-size: 20px;
}

.accnt-btn {
    display: flex;
    align-items: baseline;
    padding: 0 80px;
    gap: 25px;
}







@media(max-width: 991.98px) {
    .popBOX.BlockPopup.cancel-invoice {
        width: 246px !important;
        height: 74%;
    }

    div#five-block-divs-two-rows-top {
        display: block;
    }

    .popBOX.BlockPopup.filterpopup {
        width: 300px !important;
        height: 74%;
    }

    .invoice-reject {
        display: block;
        text-align: center;
    }

    .create-btn {
        margin-bottom: 5px;
    }

    .pagination-secyion,
    .years-part {
        display: block;
    }

    .five-block-divs-two-rows-top-inner {
        width: 79%;
        margin: 0 auto 10px;
    }

    div#five-block-divs-two-rows {
        margin: auto;
    }

    div#speciality-3 {
        padding: 0;
    }

    .accnt-btn {
        display: block;
        padding: 0;
    }

    button.filter-btn {
        margin-bottom: 10px;
    }
}


.qr-detail.cancel-invoice {
    margin-top: 0;
}

.popBOX.BlockPopup.cancel-invoice {
    height: auto !important;
}

.heading-accounting{
    text-align: center;
}

.accounting-btn{
    text-align: center;
    margin: 20px 0px;
}

.accounting-btn button, .accounting-btn button:hover{
    background-color: #0065FF;
    color: #fff;
    padding: 8px;

}

div#demo-simple-select2 {
    padding:8.5px 14px;
    color: #fff !important;
    background-color: #f95f53;
    border-radius: 25px;
    margin: 15px 0px;
}

div#block-chain svg {
    color: #fff;
}



div#menu- li.MuiButtonBase-root.MuiMenuItem-root{
    white-space: normal;
}
