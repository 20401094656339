.dashboard-body {
    padding: 74px 0 0;
}

.dashboargraph-inner-mid-left h5 {
    font-size: 24px;
    color: #000;
    margin: 0px;
    margin-bottom: 5px;
}

.dashboargraph-inner-mid-left p {
    color: #000;
    margin: 0px;
}

.dashboargraph-inner-mid-left {
    text-align: left;
}

div#dashboargraph {
    padding: 0px;
}

.dashboargraph-part {
    padding: 30px;
    background: #fff;
    border-radius: 30px;
    margin-bottom: 35px;
}

.dashboargraph-inner-top {
    text-align: right;
}

.total-pdts p {
    margin: 0px;
}

.total-pdts {
    padding: 30px 0 30px 30px;
    border-radius: 29.635px;
    background: #1755E7;
}

.total-pdts-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-pdts-div-left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.total-pdts-div-left label {
    color: #C9C9C9;
    font-size: 16px;
    margin-bottom: 0;
}

.total-pdts-div-left span {
    display: inline-block;
    font-size: 30px;
    color: #fff;
    font-weight: 600;
}

.total-pdts p {
    text-align: left;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0px;
    max-width: 265.478px;
}

.QR-codes {
    display: flex;
}

p.font-size-cmn {
    margin: 0px;
    font-size: 20px;
    color: #000;
}

.QR-codes, .monthly-chart {
    border-radius: 18.522px;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.46957px 0px 0px #FFF inset, 2.46957px 9.87826px 29.63479px 0px rgba(0, 0, 0, 0.16);
    align-items: center;
    padding-bottom: 20px;
    margin-top: 20px;
}

.monthly-chart{padding-bottom: 0px;}

.total-qr-codes-activated {
    width: 50%;
}

.monthly-chart.daily-cost h5 {
    text-align: left;
    margin: 0px;
    padding: 15px 10px 0px 15px;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}

.start-end-date fieldset {
    border: none;
    border-radius: 10px;
}

.start-end-date input {
    background: #fff;
}
@media all and (max-width:767.98px) {  /*Edited by sankar*/
    .start-end-date > .MuiStack-root {
        display: flex;
        flex-wrap: nowrap;
        flex-direction:column !important;
        gap: 20px;
    }  
}


.start-end-date > .MuiStack-root {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 20px;
}

.start-end-date .MuiStack-root {
    margin-top: 0px;
}

.start-end-date .MuiStack-root p {
    text-align: left;
}

.start-end-date {
    margin-bottom: 20px;
}

.start-end-date .MuiInputBase-root {
    background: #1976d2;
}

.start-end-date svg{color: #fff;}