.adminFilterPopup .reason {
  color: #151515;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.111px;
  text-align: center;
  margin-bottom: 30px;
}

.adminFilterPopup ::-webkit-input-placeholder {
  text-align: left;
}

.adminFilterPopup .listOne,
.adminFilterPopup .listTwo,
.adminFilterPopup .listThree {
  display: flex;
  gap: 35px;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.popBOX.adminFilterPopup.adminFilterPopup-postsale.pppp {
  height: 80% !important;
}

.popBOX.adminFilterPopup.adminFilterPopup-postsale.pppp .listOne,
.popBOX.adminFilterPopup.adminFilterPopup-postsale.pppp .listThree {
  justify-content: center;
}

@media all and (max-width: 567.98px) {
  .adminFilterPopup .wholeButton {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
  }
}

.adminFilterPopup .wholeButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.filterBtn {
  color: #fff !important;
  font-size: 20.565px !important;
  font-weight: 400 !important;
  border-radius: 5.313px;
  border: 2.656px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
  text-transform: capitalize !important;
  width: 165px;
  height: 41px;
}

/* .filter-colors{
    color: #0065FF !important;
    font-size: 20.565px !important;
    font-weight: 400 !important;
    border-radius: 5.313px;
    background: #fff !important;
    text-transform: capitalize !important;
    width: 165px;
    height: 41px;
} */
.adminFilterPopup .warn {
  padding: 0;
  margin: 0;
  margin-top: 0;
}

.popBOX.adminFilterPopup .productId h2 {
  margin-bottom: 12px;
}

.popBOX.adminFilterPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60% !important;
  border-radius: 11.129px !important;
  border: 0.658px solid rgba(203, 239, 255, 0.16) !important;
  background: rgb(233 235 237) !important;
  backdrop-filter: blur(65.84379577636719px) !important;
  padding: 50px !important;
}

.popBOX.adminFilterPopup .cmn-input-cls .MuiInputBase-root {
  width: 196px !important;
}

.popBOX .filter-box {
  display: flex;
  gap: 15px;
}

.popBOX .filter-btn button {
  padding: 8px 19px;
  gap: 4px;
  font-size: 0.875rem !important;
  width: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.popBOX .menu {
  text-align: right;
  margin-top: -8px;
  margin-bottom: 10px;
}

.popBOX .menu img {
  width: 30px;
}

.popBOX.modelTypo {
  color: #151515 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: 0.24px;
  margin-bottom: 18px !important;
}

.popBOX .start-end-date {
  margin-bottom: 27px;
}

.popBOX .start-end-date .MuiStack-root {
  margin-top: 0px;
}

.popBOX .start-end-date > .MuiStack-root {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: -20px;
}

.popBOX .start-end-date .MuiStack-root p {
  text-align: left;
}

.popBOX .start-end-date .MuiInputBase-root {
  background: #1976d2;
  width: 100%;
}

.adminFilterPopup .start-end-date input {
  background: #fff;
  color: #000 !important;
  height: 10px;
  width: 93px;
}

.popBOX .start-end-date fieldset {
  border: none;
  border-radius: 10px;
}

.popBOX .start-end-date svg {
  fill: #fff !important;
}

.popBOX .listTwo {
  gap: 38px !important;
}

.adminFilterPopup .listThree {
  gap: 20% !important;
  justify-content: flex-start;
}

.popBOX .listOne .textField input {
  width: 400px !important;
}

.popBOX .listTwo .textField input,
.popBOX .listThree .textField input {
  width: 220px !important;
}

.popBOX .apply {
  color: #fff !important;
  font-size: 17.492px !important;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize !important;
  border-radius: 4.519px;
  border: 2.259px solid #00ff29;
  background: linear-gradient(134deg, #0f6 -26.79%, #8b8b8b 230.75%);
  /* box-shadow: 0px 2.25939px 0px 0px #FFF inset, 2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16); */
  padding: 7px 40px !important;
}

.adminFilterPopup .reset {
  border-radius: 4.519px;
  border: 2.259px solid #faff00;
  background: linear-gradient(134deg, #ffb800 -26.79%, #8b8b8b 230.75%);
  color: #fff;
  text-transform: capitalize;
  padding: 7px 30px;
}

.popBOX .checkBox input.PrivateSwitchBase-input {
  color: #fff !important;
}

/* AdminSettingPopup */
.AdminSettingPopup .setting {
  border-radius: 5.313px;
  border: 2.656px solid #ff001f;
  background: linear-gradient(134deg, #f00 -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
  text-transform: capitalize;
}

.AdminSettingPopup .listOne {
  justify-content: flex-start !important;
  gap: 30%;
}

.AdminSettingPopup .cmn-input-cls {
  margin-bottom: 20px;
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.AdminSettingPopup ::-webkit-input-placeholder {
  color: #000 !important;
}

.adminFilterPopup-postsale .warn {
  margin-top: 0px !important;
}

.popBOX.adminFilterPopup.AdminSettingPopup.AdminSettingPopup-postsale {
  height: auto !important;
}

.popBOX.adminFilterPopup.AdminSettingPopup.AdminSettingPopup-postsale
  .productId {
  padding: 5px 15px;
}

.popBOX.adminFilterPopup.AdminSettingPopup.AdminSettingPopup-postsale
  .MuiInputBase-root {
  width: 100% !important;
}

.popBOX.adminFilterPopup.AdminSettingPopup.reopen.AdminSettingPopup-postsale.AdminChangeSettingPopup-postsale {
  height: 80vh !important;
}

.Select-the-reason-checkboxes .warn {
  gap: 0px !important;
  margin-top: 0px !important;
}

.Select-the-reason-checkboxes-head {
  margin-left: 0px !important;
}

.Select-the-reason-checkboxes {
  justify-content: flex-start !important;
}

/* AdminSettingPopup */
@media (max-width: 767px) {
  .popBOX {
    width: 87% !important;
    bottom: -100px !important;
    padding: 35px 19px 35px 20px !important;
    height: 93%;
  }

  .popBOX .warn {
    padding: 0;
    margin: 0;
    margin-top: 0px;
  }

  .popBOX .start-end-date {
    margin-bottom: 18px;
  }

  .popBOX .listOne,
  .popBOX .listTwo,
  .popBOX .listThree {
    display: inline !important;
  }

  .popBOX .start-end-date > .MuiStack-root {
    display: inline;
  }

  .popBOX .start-end-date .MuiStack-root {
    margin-bottom: 20px;
  }

  .popBOX .menu {
    margin-top: -24px;
  }

  .adminFilterPopup .start-end-date .MuiInputBase-root {
    width: 100%;
  }

  .popBOX {
    overflow-x: hidden;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .popBOX {
    width: 657px !important;
    padding: 50px 19px 50px 32px !important;
  }

  .popBOX .listOne .textField input {
    width: 243px !important;
  }

  .popBOX .listOne,
  .popBOX .listTwo,
  .popBOX .listThree {
    display: flex;
    gap: 0px;
  }

  .popBOX .start-end-date .MuiInputBase-root {
    width: 91%;
  }

  .popBOX .listTwo .textField input,
  .popBOX .listThree .textField input {
    width: 187px !important;
  }

  .popBOX .listTwo {
    gap: 0px !important;
  }

  .listThree {
    gap: 0px !important;
  }

  .modelTypo {
    font-size: 15px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .popBOX {
    width: 800px !important;
    padding: 39px 19px 39px 47px !important;
  }

  .popBOX .listOne .textField input {
    width: 327px !important;
  }

  .popBOX .start-end-date .MuiInputBase-root {
    background: #1976d2;
    width: 90%;
  }

  .popBOX .listOne,
  .popBOX .listTwo,
  .popBOX .listThree {
    display: flex;
    gap: 9px;
  }
}
