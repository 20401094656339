.feedback-table-img {
    object-fit: cover;
    width: 60px;
    height: auto;
    background: #ebebeba4;
    padding: 5px;
    border-radius: 10px;
}

.txt-field-feedback {
    border: 1px solid #000 !important;
    border-radius: 8px;

}

.txt-field-feedback input {
    padding: 9.5px 14px !important;
    width: 200px;
}

.tablebody.fb {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.feedbck-btn button {
    background: #0063cc !important;
    color: #fff;
    margin: 15px 0px;
}

.feedbck-btn {
    text-align: center;
}

.table-container-feedback {
    background: #fff;
    border-radius: 10px;
    text-align: center;
}

.description-div {
    white-space: wrap !important;
    text-align: center !important;
    word-break: break-all !important;
}