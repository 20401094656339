.Generate-certificate-page .watch-description {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    height: 20px !important;
}

.Generate-certificate-page .map-component {
    border-radius: 20.418px;
    border: 4.084px solid #0070F0;
    background: #D9D9D9;
}

.Generate-certificate-page input::placeholder {
    text-align: left;
}

.Generate-certificate-page .map-component iframe {
    border-radius: 20.418px;
}

.Generate-certificate-page .ref-text2 {
    text-align: left !important;
}

.Generate-certificate-page .reference-body {
    align-items: flex-start;
}

.Generate-certificate-page .avatar-div .MuiAvatar-root.MuiAvatar-circular {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 9.528px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    background-color: #D9D9D9 !important;
    border-radius: 50%;
    border: 1px solid #000;
}

.Generate-certificate-page .ref-text {
    color: #0070F0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.Generate-certificate-page .ref-text p {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize;
}

.Generate-certificate-page .gradient-border {
    margin-bottom: 20px;
    width: 100%;
}

.Generate-certificate-page .sales {
    display: flex;
    align-items: center;
    gap: 30px;
}

.Generate-certificate-page .sales .watch-description {
    max-width: 185px;
}

.Generate-certificate-page .col-svg {
    height: max-content;
    margin: 20px 0;
}

.Generate-certificate-page .price p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px !important;
}

.Generate-certificate-page .price h6 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.Generate-certificate-page .watch-div {
    margin-top: 0;
}

.Generate-certificate-page .gen-btn {
    border-radius: 17px;
    border: 2.899px solid #E42B15;
    background: linear-gradient(134deg, #FF1B00 -26.79%, #756563 230.75%);
    padding: 8px 15px;
}

.Generate-certificate-page .gen-btn button {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.Generate-certificate-page .user-logo {
    display: flex;
    align-items: center;
    width: auto !important;
    margin-top: 0;
}

.Generate-certificate-page .user-logo div span {
    color: #58A626;
    text-align: center;
    font-family: Roboto;
    font-size: 16.334px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Generate-certificate-page .warrenty-body {
    /* padding: 50px 0; */
}

.Generate-certificate-page .watch-sub-description {
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 323px;
}

.Generate-certificate-page fieldset.MuiOutlinedInput-notchedOutline {
    border-radius: 4.198px;
    background: #FFF;
    box-shadow: 3.14833px 3.14833px 7.34609px 0px rgba(156, 156, 156, 0.48) inset, -3.14833px -3.14833px 7.34609px 0px #FFF inset;
    z-index: 0;
}

.Generate-certificate-page .email-input {
    border-radius: 4.198px;
    border: 1.049px solid #FFF;
    opacity: 0.6;
    padding: 3px;
    background-color: #DFE5F4;
    width: 44% !important;
}

.Generate-certificate-page .email-input input {
    color: #000 !important;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    z-index: 1;
}

.Generate-certificate-page .watch-sub-description span {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.Generate-certificate-page .user-logo.generate2 {
    margin-top: 20%;
}

.Generate-certificate-page .Guarantee {
    justify-content: center;
    font-size: 24px;
    max-width: 200px;
    padding: 15px 66px;
}

.Generate-certificate-page.page3 .watch-sub-description {
    max-width: 447px;
}

.Generate-certificate-page.page3 .gen-btn {
    display: flex;
}

.Generate-certificate-page.page3 .gen-btn {
    border-radius: 10px;
    border: 2.899px solid #00FF29;
    background: linear-gradient(134deg, rgba(0, 255, 41, 1) -26.79%, #756563 230.75%);
    padding: 8px 15px;
}

.Generate-certificate-page.page3 .gen-btn button {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.Generate-certificate-page.page3 .user-logo {
    margin-top: 10%;
}

.watch-id-text label span {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width:1349.98px) {
    .Generate-certificate-page .ref-text {
        flex-direction: column;
        word-break: break-all;
        align-items: center;
    }

    .trns-body {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width:575px) {

    .Generate-certificate-page .watch-description,
    .Generate-certificate-page .watch-sub-description span,
    .Generate-certificate-page .email-input input {
        font-size: 16px !important;
    }

    .Generate-certificate-page .watch-sub-description {
        font-size: 16px !important;
        width: 80% !important;
        text-align: justify;
    }

    .Generate-certificate-page .email-input {
        width: 80% !important;
    }

    .Generate-certificate-page.page3 .Guarantee {
        font-size: 16px;
        max-width: 40%;
    }

    /* .collectBtn svg, svg {
        font-size: 18px !important;
        width: 100% !important;
    } */
    .Generate-certificate-page.page3 .gen-btn button {
        font-size: 14px !important;
    }

    .Generate-certificate-page.page3 .gen-btn {
        padding: 0px 8px;
    }

    /* .gen-btn svg{
        width: 25% !important;
    } */

}

@media (max-width: 767.98px) {
    .warrenty-body {
        width: auto;
    }

    .extented-warrenty {
        flex-direction: column;
        margin-top: 70px;
        padding: 0 20px;
    }

    .map-component {
        width: auto;
    }

    .Generate-certificate-page .warrenty-body {
        margin: 35px 5px;
    }

    .Generate-certificate-page .watch-description {
        max-width: 200px;
        margin-bottom: 15px;
    }

    .Generate-certificate-page .sales,
    .trns-body {
        flex-direction: column;
        gap: 0;
        justify-content: center;
        align-items: center;
    }

    .Generate-certificate-page .trns-body {
        gap: 10px;
    }

    .gradient-border svg {
        width: 100px;
    }
}

@media (min-width: 768px) and (max-width:991.98px) {
    .email-input .textField {
        width: 100%;
    }
}



.Generate-certificate-page .map-component.genral1 {
    background: transparent !important;
    border: none !important;
    border-radius: 0px !important;
}

.Generate-certificate-page .map-component.genral1 .leaflet-container {
    height: 300px;
    z-index: 1;
    margin-top: 30px;
}