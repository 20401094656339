.dashboard-body {
    padding: 50px 0;
}

.dashboargraph-inner-mid-left h5 {
    font-size: 24px;
    color: #000;
    margin: 0px;
    margin-bottom: 5px;
}
.countryspan{
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.dashboargraph-inner-mid-left p {
    color: #000;
    margin: 0px;
}

.dashboargraph-inner-mid-left {
    text-align: left;
}

div#dashboargraph {
    padding: 0px;
}

.dashboargraph-part {
    padding: 30px;
    background: #fff;
    border-radius: 30px;
    margin-bottom: 35px;
}

.dashboargraph-inner-top {
    text-align: right;
}

.total-pdts p {
    margin: 0px;
}

.total-pdts {
    padding: 30px 0 30px 30px;
    border-radius: 29.635px;
    background: #1755E7;
}

.total-pdts-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-pdts-div-left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.total-pdts-div-left label {
    color: #C9C9C9;
    font-size: 16px;
    margin-bottom: 0;
}

.total-pdts-div-left span {
    display: inline-block;
    font-size: 30px;
    color: #fff;
    font-weight: 600;
}

.total-pdts p {
    text-align: left;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0px;
    max-width: 265.478px;
}

.QR-codes {
    display: flex;
}

p.font-size-cmn {
    margin: 0px;
    font-size: 20px;
    color: #000;
}

.QR-codes,
.monthly-chart {
    border-radius: 18.522px;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.46957px 0px 0px #FFF inset, 2.46957px 9.87826px 29.63479px 0px rgba(0, 0, 0, 0.16);
    align-items: center;
    padding-bottom: 20px;
    margin-top: 20px;
}

.monthly-chart {
    padding-bottom: 0px;
}

.total-qr-codes-activated {
    width: 50%;
}

.monthly-chart.daily-cost h5 {
    text-align: left;
    margin: 0px;
    padding: 15px 10px 0px 15px;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}

.start-end-date fieldset {
    border: none;
    border-radius: 10px;
}

.start-end-date input {
    background: #fff;
}

.start-end-date>.MuiStack-root {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    gap: 20px;
}

.start-end-date .MuiStack-root {
    margin-top: 0px;
}

.start-end-date .MuiStack-root p {
    text-align: left;
}

.start-end-date {
    margin-bottom: 20px;
}

.start-end-date .MuiInputBase-root {
    background: #1976d2;
}

.start-end-date svg {
    color: #fff;
}

div#speciality-1,
div#speciality-2,
div#speciality-3,
div#speciality-4 {
    padding: 30px 5px;
}

div#speciality-1,
div#speciality-2,
div#speciality-3,
div#speciality-4 {
    padding: 30px 5px;
}

div#two-block-divs-1,
div#two-block-divs-2 {
    padding: 30px 5px;
}

div#earnings-sales {
    margin: 30px 15px 30px 5px;
    background: #fff !important;
    border-radius: 20px !important;
    overflow: hidden;
}

div#earnings-sales th,
#recent-earnings-sales th {
    background: rgba(174, 192, 236, 0.80);
}

#recent-earnings-sales {
    margin: 30px 5px;
    background: #fff !important;
    border-radius: 20px !important;
    overflow: hidden;
}

h3.head-table-recent {
    margin: 0px;
}

p.para-head-table-recent {
    margin: 0px;
    font-size: 14px;
}

div#recent-earnings-sales td.MuiTableCell-root {
    padding-top: 13px;
    padding-bottom: 14px;
}

.QR-codes-mobile-sessions {
    display: flex;
    align-items: flex-start;
}

.QR-codes-mobile-session-left {
    text-align: left;
}

.QR-codes-mobile-session-left h5 {
    text-align: left;
    margin: 0px;
    padding: 0px 10px 0px 0;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}

.total-qr-codes-activated-mobile {
    border-radius: 18.522px;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.46957px 0px 0px #FFF inset, 2.46957px 9.87826px 29.63479px 0px rgba(0, 0, 0, 0.16);
    align-items: center;
    padding: 10px 20px;
}

div#two-block-divs-2 .total-pdts {
    background: #E7B917;
}

div#country-tracelable {
    height: 92%;
    border-radius: 30px !important;
    background: rgba(79, 19, 200, 1) !important;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding: 0 20px;
}

div#country-tracelable path {
    fill: #fff;
}

path#US-TX {
    fill: #00e396 !important;
}

/* .map-whole {
    overflow: auto;
    height: 500px;
} */

div#map-country-use-product {
    margin-top: 60px;
}


div#map-country-use-product {
    margin-top: 60px;
    background: #fff;
    padding: 0px;
    max-width: 1343px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-radius: 20px;
}

div#map-country-use-product-left {
    padding: 0 20px;
}

div#map-country-use-product-left h4 {
    text-align: left;
    font-size: 30px;
    color: #000;
    margin-bottom: 0px;
}

div#map-country-use-product-left p {
    text-align: left;
    font-size: 16px;
    color: #000;
}

div#map-country-use-product-left>span {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.map-whole svg {
    transform: scale(0.7) translateX(-128px);
}

div#map-country-use-product-left span.MuiLinearProgress-root {
    margin-bottom: 30px;
}

.map-whole path {
    fill: #0f006e;
}



div#speciality-1 .total-pdts-div-right,
div#speciality-2 .total-pdts-div-right,
div#speciality-3 .total-pdts-div-right,
div#speciality-4 .total-pdts-div-right {
    width: 43%;
}

div#speciality-1 .total-pdts-div-left,
div#speciality-2 .total-pdts-div-left,
div#speciality-3 .total-pdts-div-left,
div#speciality-4 .total-pdts-div-left {
    width: 56%;
}

div#speciality-1 .total-pdts-div,
div#speciality-2 .total-pdts-div,
div#speciality-3 .total-pdts-div,
div#speciality-4 .total-pdts-div {
    flex-wrap: wrap;
}

div#speciality-1 .total-pdts,
div#speciality-2 .total-pdts,
div#speciality-3 .total-pdts,
div#speciality-4 .total-pdts {
    overflow: hidden;
}

.five-blk-inner {
    display: flex;
    white-space: nowrap;
}

.five-blk-inner-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    gap: 1px;
}

.five-blk-inner {
    gap: 10px;
}

.five-block-img {
    width: 50px;
    height: 50px;
    background: rgba(47, 128, 237, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.five-blk-inner-content label {
    font-size: 14px;
    color: #000;
}

.five-blk-inner-content span {
    font-size: 17px;
    font-weight: 600;
    color: #000;
    letter-spacing: -0.5px;
}

div#five-block-divs {
    padding: 0px;
    background: rgba(174, 192, 236, 0.80);
    padding: 30px 0;
}

div#five-block-divs-two-rows {
    margin: 40px 80px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
}

.start-end-date.start-end-date-admin .MuiStack-root {
    justify-content: flex-end;
}

.five-block-divs-two-rows-top-inner {
    padding: 20px;
    border-radius: 16.667px;
    border: 0.986px solid rgba(203, 239, 255, 0.16);
    background: #fff;
    backdrop-filter: blur(98.61111450195312px);
}

div#five-block-divs-two-rows-top {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 40px;
}

.five-block-divs-two-rows-top-inner {
    display: flex;
    flex-direction: column;
    width: 15%;
}

.iconic-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 45px;
}

.five-block-divs-two-rows-top-inner-five-blk-inner-content {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    align-items: center;
}

.iconic-icon label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-align: left;
}

.five-block-divs-two-rows-top-inner-five-blk-inner-content label {
    font-size: 25px;
    font-weight: 600;
    color: #000;
}

.five-block-divs-two-rows-top-inner-five-blk-inner-content span {
    color: #219653;
    background: #ccc;
    padding: 2px;
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
}

div#map-country-use-product {
    max-width: -webkit-fill-available !important;
    margin-left: 80px !important;
    margin-right: 80px !important;
}

/* Responsive */

@media(max-width: 991.98px) {
    div#five-block-divs {
        padding: 30px 20px !important;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .dashboard-body div#five-block-divs {
        gap: 0 !important;
        justify-content: flex-start;
        padding: 30px 20px !important;
    }
    div#map-country-use-product{
        margin: 60px 10px 0px 10px !important;
    }

    div#earnings-sales {
        margin: 30px 15px 30px 5px;
        background: #fff !important;
        border-radius: 20px 20px 0 0 !important;
        overflow: hidden;
    }

    .five-block-divs-two-rows-top-inner {
        width: 79% !important;
        margin: 0 auto 10px;
    }

    div#earnings-sales th,
    #recent-earnings-sales th {
        text-align: center;
    }
}



/*Edited by sankar*/

@media all and (max-width: 575.98px) {
    .MuiBox-root.css-1q88n87 {
        width: auto;
    }

    .map-whole {
        overflow: scroll;
    }

}


 @media (max-width: 767.98px) {
 
    .map-whole svg {
        overflow: scroll;
        width: auto !important;

    }

}

@media (max-width:1099px) {
    div#five-block-divs-two-rows-top {
        display: block !important;
    }

    .five-block-divs-two-rows-top-inner {
        width: 79% !important;
        margin: 0 auto 10px;
    }
    .dashboard-body div#five-block-divs {
        padding: 30px 20px !important;
    }
 }


    @media (min-width:992px) and (max-width:1349.98px){
        .dashboard-body div#five-block-divs {
            gap: 0 !important;
            justify-content: flex-start;
            padding: 30px 20px !important;
        }
    }
