.profile-heading-name h1{
    text-align: center;
    font-size: 40px;
}
.profile-detail-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0px;
    align-items: center;
}

.profile-detail-part .watch-id-text{
    margin-bottom: 20px;
}
.profile-detail-part .input-textfield-block label {
    font-size: 22px;
    font-weight: 400;
    color: #000;
    width: 150px;
    text-align: center;
}

.warrenty-body.profile-body{
    width: 564px !important;
    text-align: center;
}
.profile-image-outer.profile-dp {

    border-radius: 50px;
    clip-path: circle(43% at 50% 50%);
    border: 2.192px solid #0070F0 !important;

}
.profile-image-outer.profile-dp .edit-image-btn{
    background: none !important;
}
.edit-view button {
    position: relative;
    bottom: 50px;
}
.profile-detail-part .input-textfield-block input{
    width: 341px;
}
.profile-detail-part .input-textfield-block input::placeholder {
    text-align: left;
}
.save-btn button {
    border-radius: 10.054px;
    border: 5.027px solid #00FF29;
    background: linear-gradient(134deg, #0F6 -26.79%, #8B8B8B 230.75%);
    color: #fff;
    padding: 7px 28px 6px 28px;
    font-size: 14px;
}
@media(max-width:767.98px){
    .warrenty-body.profile-body{
        width: 100% !important;
    }
    .profile-detail-part .input-textfield-block input{
        width:100% !important;
    }
 
    .profile-heading-name h1 {
        font-size: 26px;
        margin-top: 50px;
    }
    
}

@media(max-width:991.98px){
    .profile-detail-part .input-textfield-block{
        flex-direction: column !important;
      }
      .profile-detail-part .input-textfield-block label{
        font-size: 17px !important;
        text-align: left !important;
      }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .profile-detail-part .input-textfield-block input {
        width: 387px !important;
    }
}




