@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    background: url(./img/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 30px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}

::-webkit-scrollbar-track {
    background: #c6d8ff00;
}

.premission-access {
    height: calc(100vh - 350px);
}

.menu {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
    margin-right: -15px !important;
}

:focus-visible {
outline: unset !important;
}

/* .menu{
    position: sticky;
    top: 0px;
    z-index: 1;
   } */

/* .filter-overflow {
    overflow-y: scroll;
    height: 100%;
} */

/* .AdminCustomerpopBOX{
    overflow-y: hidden !important;
} */

.AdminCustomerpopBOX {
    padding: 33px !important;
}