.sellerpage .start-end-date .MuiInputBase-root {
    width: 100%;
}
.sellerpage .start-end-date input {
    background: #fff;
    border: 1px solid grey;
    height:10px;
}
.sellerpage {
    background: url(../../../img/background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.sellerpage .containerBody .innerBody{
    background: #ffffff00 !important;
    margin: auto;
    padding: 60px 30px;
}

.sellerpage .start-end-date.calender-date{
display: flex;
justify-content: space-between !important;
align-items: center;
margin-bottom: 66px;
}

.sellerpage .MuiGrid-root.MuiGrid-container.containerBody.css-11lq3yg-MuiGrid-root {
    /* max-width: 77% !important; */
    /* display: block; */
    /* margin: 48px auto; */
    justify-content: center;
}

.sellerpage .invoiceTitle{
    color: #000;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 100%;
    text-transform: capitalize;

}
.sellerpage .Title{
    text-align: center;
}
.sellerpage .Title p{
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
}
.sellerpage .monthly-chart {
    width: 700px !important;
    margin: auto;
}
.sellerpage .pagination-numb ul {
    background: #a8c3ff;
}

.sellerpage  .BtmBtn{
display: flex;
justify-content: space-between;
}

.sellerpage .InvoicePaidTable{
    margin-top: 70px;
}
.sellerpage .invoiceTableRow {
    border: 1px solid #0070f0;
    border-radius: 15px 15px 0px 0px !important;
}
.sellerpage .head-part-table{
    text-transform: capitalize !important;
}
.sellerpage .checkBoxClr svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium path{
    fill: #0070f0 !important;
}
.sellerpage .systemFile{
width: 25px;
height: 25px;
}
.sellerpage .invoiceTableBody{
    border-radius: 15px;
    background: #FFF !important;
}
.sellerpage Button.export{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    width: -moz-fit-content;
    height: fit-content;
}
.sellerpage .css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
    background-color: #fff !important;
}
.sellerpage .sub-total-direct{
border-bottom: none !important;
}
.sellerpage  .css-1wvnaxz-MuiTableCell-root.MuiTableCell-head {
    color: #fff;
    background: #0070f0 !important;
    text-align: center;
}
.sellerpage  .css-132a6sb-MuiTableCell-root{
        border-radius: 0;
        background: #fff !important;
}
.sellerpage tr .table-value {
    font-size: 14px;
    text-align: center !important;
}
.sellerpage tr .table-value svg{
    font-size: 26px;
}

.sellerpage .filter-box.account button, .add-seller .filter-box.account button{
    border-radius: 4.802px;
    border: 2.401px solid #0065ff;
    background: #0065ff;
    color: #fff !important;
    gap: 10px;
    padding: 6px 24px;
}

.sellerpage .filter-box.account {
    margin-bottom: 18px;
    justify-content: center;
}

.add-seller .listOne {
    display: flex;
    gap: 35px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.add-seller .headTitle {
    color: #000;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 70px;
}
.add-seller .filter-box.account {
    justify-content: center;
}
.popBOX.add-seller .listOne .SellerPaidTable table th{
    text-align: center;
}
.add-staff .user-txt h4 {
    color: #000;
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 15px;
}
.add-staff .user-txt p {
    color: #545454;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    max-width: 464px;
    margin: 0 auto;
}

.add-staff {
    margin-bottom: 30px;
}
.add-seller .filter-box.account button.cancel{
    border-radius: 6.2px;
border: 3.1px solid #E42B15;
background: linear-gradient(134deg, #FF1B00 -26.79%, #756563 230.75%);
padding: 10px 25px;
}
.add-seller .filter-box.account.add{
    justify-content: space-between;
}
.message {
    text-align: center;
    margin-bottom: 35px;
}

.message p {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    max-width: 541px;
    margin: 0 auto;
}

.message span {
    color: #FC2525;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
}
.message h6{
    color: #000;
text-align: center;
font-size: 16px;
font-weight: 400;
max-width: 527px;
margin: 0 auto;
margin-top: 20px;
}
.codes {
    display: flex;
    align-items: center;
    gap: 8px;
}
.codes button{
    padding: 0 !important;
    max-width: fit-content !important;
}
.action-ptxt p {
    color: #000;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
}
.map-code-svg path {
    fill: #fff;
}

.map-code-svg {
    border-radius: 15px;
    background: #4231c1 50% / cover no-repeat;
    margin: 30px;
}
.secound {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seller-map .map-code-svg svg{
    width: 650px;
}
.secound span.MuiLinearProgress-root {
    padding: 1px 0;
    margin: 15px 0;
}
.progress-bar {
    border-radius: 20px;
    background: var(--neutral-colors-white, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    padding: 50px 30px;
    box-shadow: -1px 3px 10px #ccc;
}
.progress-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.progress-txt p {
    color: #000;
    font-size: 14px !important;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0;
}
.progress-bar h4 {
    color: #1E1B39;
    font-size: 20px;
    font-weight: 700;
}
.popBOX {
    height: 80%;
    overflow: auto;
}
/* INVOICEPOPUP */

.logo-dt {
    text-align: center;
}
p.llc-head {
    font-size: 33px;
    font-weight: 700;
}
.dtllc-head {
    text-align: end;
    line-height: 1;
}
p.dtrace {
    font-size: 18px;
    font-weight: 400;
}
.client-llc-direct {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dtr {
    text-align: end;
}
.head-part-table {
    background: #0070F0 !important;
}

.table-value {
    background: #CFE1FF;
}
.sub-total-direct {
    display: flex;
    align-items: baseline;
    gap: 50px;
    justify-content: end;

}
.client-detail-table, .InvoicePaidTable {
    overflow: auto;
    border-radius: 15px;
}
.popBOX.BlockPopup {
    height: 81%;
    overflow: auto;
}
.sub-total-direct {
    border-bottom: 1px solid #ccc;
}
.export-btn {
    display: flex;
    gap: 25px;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}
.dtllc-head a {
    text-decoration: none;
    color: #000;
}
.total-box {
    border-radius: 15px;
    border-top: 1.3px solid #0070F0;
    border-left: 1.3px solid #0070F0;
    background: #FFF;
    box-shadow: 8px 7px 19px 0px rgba(0, 0, 0, 0.10);
    padding: 15px 29px;
    margin-top: 20px;
}
.table-value {
    max-width: 136px;
}
.button-cls {
    display: flex;
    gap: 11px;
}
.cls-sub {
    background: #4198FB !important;
    color: #fff !important;
    font-weight: 500 !important;
}
.seller-store-list {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.delete-seller-store svg {
    color: #f00;
}
.filter-box.account.add.seller-store-delta-email-btn {
    justify-content: center;
    margin-top: 40px;
}

.no-of-staff-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
}

.seller-store-delta-email-btn-ok button{width: 100px;}

.SellerPaidTable-table {
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media(max-width: 991.98px){
    .client-llc-direct{
        display: block;
    }
    p.llc-head{
        text-align: center;
    }
    .dtllc-head {
        text-align: center;
    }
    .dtr {
        text-align: center;
    }
    .sub-total-direct{
        justify-content: center;
    }
    .total-box{
        margin-top: 0;
    }
    .SellerPaidTable table {
        width: auto;
        display: block;
    }
}



/* INVOICEPOPUP */




/* INVOICEFILTERPOPUP */


.filterBtn{
    color: #FFF !important;
    font-size: 20.565px !important;
    font-weight: 400 !important;
    border-radius: 5.313px;
    border: 2.656px solid #0065FF;
    background: linear-gradient(134deg, #0065ff -26.79%, #8B8B8B 230.75%);
    text-transform: capitalize !important;
    width: 165px;
    height: 41px;
}
.popBOX.InvoicesPopup .headTitle{
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.popBOX.InvoicesPopup{
    background: #FFF !important;
}
.popBOX.InvoicesPopup .start-end-date{
    margin-left: 6%;
}
.popBOX.InvoicesPopup {
    width: 70% !important;
}
.popBOX.InvoicesPopup .listOne{
flex-direction: row-reverse;
justify-content: space-between;
}
.popBOX.InvoicesPopup .modelTypo.create{
    text-align: center;
}
.popBOX.InvoicesPopup .start-end-date > .MuiStack-root {
    gap: 0px;
}
.popBOX.InvoicesPopup .modelTypo{
    text-transform: capitalize !important;
    margin-bottom: 10px;
}
.popBOX.InvoicesPopup .apply{
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
}
.popBOX.InvoicesPopup .butOutLayer {
    background: #0065FF;
    width: -moz-fit-content;
    width: fit-content;
    border: 2.259px solid #0065FF;
    border-radius: 4.519px;
    margin: auto !important;
}
.popBOX.InvoicesPopup .cmn-input-cls {
    margin-bottom: 20px;
    border-radius: 6.458px;
    border: 1.615px solid #FFF;
    opacity: 0.6;
}
.popBOX{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1000px !important;
    border-radius: 11.129px !important;
    border: 0.658px solid rgba(203, 239, 255, 0.16) !important;
    background:rgb(233 235 237) !important;
    backdrop-filter: blur(65.84379577636719px) !important;
    padding: 50px 80px!important;
}
/* .popBOX .cmn-input-cls .MuiInputBase-root{
    width: 300px !important;

} */
.popBOX .filter-box {
    display: flex;
    gap: 15px;
}

.popBOX .filter-btn button{
    padding: 8px 19px;
    gap: 4px;
    font-size: 0.875rem !important;
    width: auto;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.popBOX .menu{
    text-align: right;
    margin-top: -8px;
    margin-bottom: 10px;
}
.popBOX .menu img {
    width: 30px;
}
.popBOX.modelTypo{
    color: #151515 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    letter-spacing: 0.24px;
    margin-bottom: 18px !important;
}

.popBOX .start-end-date {
    margin-bottom: 27px;
}

.popBOX .start-end-date .MuiStack-root {
    margin-top: 0px;
}
.popBOX .start-end-date > .MuiStack-root {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: -20px;
}
.popBOX .start-end-date .MuiStack-root p {
    text-align: left;
}
.popBOX .start-end-date .MuiInputBase-root {
    background: #1976d2;
    width: 90%;
}
.popBOX .start-end-date input {
    background: #fff;
    color: #000 !important;
    height: 10px;
    border: 1px solid grey;
}
.popBOX .start-end-date fieldset {
    border: none;
    border-radius: 10px;
}
.popBOX .start-end-date svg {
    fill: #fff !important;
}
.listOne, .popBOX .leftCheck, .listThree{
    display: flex;
    gap: 35px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.popBOX .listTwo{

    justify-content: space-between;
}
.popBOX .leftCheck{
    gap: 38px !important;
    align-items: stretch;
}
.popBOX .listThree{
    gap: 26px !important;
}
.popBOX .listOne .textField input{
    width: 400px !important;
}
.popBOX .listTwo .textField input, .popBOX .listThree .textField input{
    width: 220px !important;
}
.popBOX .warn {
    padding: 0;
    margin: 0;
    margin-top: -37px;
}
.popBOX .apply{
    color: #FFf !important;
    font-size: 17.492px !important;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize !important;
    border-radius: 4.519px;
    border: 2.259px solid #00FF29;
    background: linear-gradient(134deg, #0F6 -26.79%, #8B8B8B 230.75%);
    /* box-shadow: 0px 2.25939px 0px 0px #FFF inset, 2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16); */
    padding: 7px 40px  !important;
}
.popBOX .butOutLayer{
    background: #00FF29;
    width: fit-content;
    border: 2.259px solid #00FF29;
    border-radius: 4.519px;
    margin: auto !important;
}

.popBOX .checkBox input.PrivateSwitchBase-input{
    color: #fff !important;
}

@media(max-width: 767px) {
    .popBOX{
        width:87% !important;
        bottom: -100px !important;
        padding: 35px 19px 35px 20px !important;
        height: 93%;
    }
    .popBOX .warn {
        padding: 0;
        margin: 0;
        margin-top: 0px;
    }
    .popBOX .start-end-date {
        margin-bottom: 18px;
    }
    .popBOX .listOne, .popBOX .listTwo, .popBOX .listThree{
        display: flex !important;
        flex-direction: column !important;
        align-items:center !important;
    }
    .popBOX .listOne .textField input {
        width: 244px !important;
    }
    .popBOX .start-end-date > .MuiStack-root {
        display: inline;
    }
    .popBOX .start-end-date .MuiStack-root{
        margin-bottom: 20px;
    }
    .popBOX .menu {
        margin-top: -24px;
    }
    .popBOX .start-end-date .MuiInputBase-root {
        width: 91%;
    }
    .popBOX{
        overflow-x: hidden ;
    }
    .message p{
        font-size: 20px;
    }
    .message span{
        font-size: 25px;
    }
    .popBOX.add-seller {
        width: 70% !important;
    }
    .add-seller .headTitle{
        margin-bottom: 30px;
        font-size: 25px;
    }
    .add-seller .filter-box.account {
        justify-content: center;
        margin-top: 20px;
    }    
}

@media (min-width: 768px) and (max-width: 991.98px) {
.popBOX {
    width: 657px !important;
    padding: 50px 19px 50px 32px !important;
}
.popBOX .listOne .textField input {
    width: 243px !important;
}
.popBOX .listOne, .popBOX .listTwo, .popBOX .listThree {
    display: flex;
    gap: 0px;
    align-items:center !important;
}
.popBOX .start-end-date .MuiInputBase-root {
    width: 91%;
}
.popBOX .listTwo .textField input, .popBOX .listThree .textField input {
    width: 187px !important;
}
.popBOX .listTwo {
    gap: 0px !important;
}
.listThree {
    gap: 0px !important;
}
.modelTypo {
    font-size: 15px !important;
}

}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .popBOX {
        width: 800px !important;
        padding: 39px 19px 39px 47px !important;
    }
    .popBOX .listOne .textField input {
        width: 327px !important;
    }
    .popBOX .start-end-date .MuiInputBase-root {
        background: #1976d2;
        width: 90%;
    }
    .popBOX .listOne, .popBOX .listTwo, .popBOX .listThree {
        display: flex;
        gap: 9px;
        align-items:center !important;
    }
    
}

/* INVOICEFILTERPOPUP */