.my-product-body-main {
    width: 80%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 23.821px;
    background: #DFE5F4;
    margin: 20px 0px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    align-items: center;
    /* justify-content: center; */
}

.productdemo-img img {
    width: 100px;
    height: 100px;
}

.my-products {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.product-text {
    margin-top: 25px;
    width: 256.563px;
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
}

.product-list {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.product-list-text {
    width: 241.733px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 133.188%;
    /* 23.974px */
    text-transform: capitalize;
    margin-top: 20px;
}

.lists {
    width: 440.458px;
    height: auto;
    border-radius: 7.415px;
    border: 1.483px solid #0070F0;
    background: #DFE5F4;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px 0px;
    cursor: pointer;
}

.product-img img {
    width: 150px;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.product-id {
    width: 333px;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.product-description {
    width: 263.978px;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 128.188%;
    /* 19.228px */

}

.watch-id2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0px;
    align-items: center;
    gap: 30px;
}


.product-view-icon {
    width: 36.629px;
    height: 54.944px;
    border-radius: 7.044px;
    border: 1.127px solid #0070F0;
    background: rgba(158, 203, 254, 0.20);
    padding: 15px 30px;
}

.view-inner-display {
    display: flex;
    gap: 15px;
}

.brand {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.my-product-popup {
    width: 478.17px;
    height: 472.511px;
    border-radius: 35.368px;
    background: rgba(38, 38, 38, 0.32);
    backdrop-filter: blur(28.294076919555664px);
}

.product-pop-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-img {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    gap: 40px;

}

.popup-img::-webkit-scrollbar {
    height: 15px !important;
}

.popup-img::-webkit-scrollbar-track {
    background-color: #D9D9D9;
}

.popup-img::-webkit-scrollbar-thumb {
    background-color: #0070F0;
}

.clock-div1 {
    transform: rotate(45deg);
}

.clock-div2 {
    transform: rotate(90deg);
}


@media (max-width:768.68px) {
    .lists {
        width: 95%;
    }

    .product-id {
        width: 100%;
    }

    .product-list {
        width: 100% !important;
    }

    .my-product-body-main {
        width: 90% !important;
    }

}

@media (max-width:568.68px) {
    .view-inner-display {
        flex-direction: column;
    }

    .myproduct-popup {
        width: 85% !important;
        padding: 20px !important;
    }
}

.myproductlist-btn {
    display: flex;
    gap: 15px;
    /* margin-top: 55px; */
    margin-left: 22px;
}

.myproductlist-btn button {
    background-color: #0070F0;
    color: #fff;
    /* font-size: 10px; */
    padding: 10px;
}

.myproductlist-btn button:hover {
    background-color: #0070F0;
    color: #fff;
    padding: 10px;
}

@media (max-width:991.98px) {
    .myproductlist-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-left: 0px;
        align-items: center;
        justify-content: center;
    }

    .my-product-body-main {
        margin: 40px 0px;
    }
}