.Admin.Customer .listbody{
    padding: 50px 0;

}
#brands{
    display: flex;
    justify-content: center;
}
.thumbalin {
    height: auto;
    width: auto;
    border-radius: 5px;
}
.image-box{
    height: 150px;
    width: 150px;
    border-radius: 5px;
}
.image-box img{
    border-radius: 5px;
    object-fit: cover;
        width: 100%;
        height: 100%;
    }
.thumbalin img{
border-radius: 5px;
object-fit: cover;
    width: 60px;
    height: 60px;
}
.Admin.Customer .filter-btn button {
    padding: 8px 24px;
    gap: 4px;
}
.Admin.Customer .filterBtn{
    width: auto;
    font-size: 0.875rem !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 5.313px;
    border: 2.656px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
}
.Admin.Customer .filter-btn .filterBtn{
    width: auto;
    font-size: 0.875rem !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 5.313px;
    border: 2.656px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);

}
.Admin.Customer .head-bg {
    background: rgba(174, 192, 236, 0.80) !important;
    color: #000 !important;
    text-align: center !important;
     line-height: 1.2 !important;
     font-weight: 600 !important;
     text-transform: capitalize;
}
.Admin.Customer .filter-box {
    display: flex;
    gap: 20px;
}
.Admin.Customer .tablebody {
    padding-top: 30px;
    /* overflow: auto; */
}
.Admin.Customer Table.tableScroll{
    overflow-x: auto;
    display: block;
}
.Admin.Customer .save-icon svg {
    color: #fff;
}

.Admin.Customer .body-bg {
    background: rgba(255, 255, 255, 0.76);
    border-bottom: 2px solid #0070F0!important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 5px;
}
.Admin.Customer .icon-space {
    display: flex;
}
.Admin.Customer .filter-btn {
    margin-top: 60px;
}
.Admin.Customer .filter-color{
    color: #000 !important;
}

.Admin.Customer .contain-width {
   max-width:93%;
    margin: 0 auto;
}
.Admin.Customer .filter-filed {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Admin.Customer .download-keys {
    margin-top: 15px;
    display: flex;
    gap: 10px;
}
.Admin.Customer .save-icon{
    border: 2px solid #0354CE;
    padding: 10px;
    border-radius: 12px;
    background: #0070F0;
}
.Admin.Customer .print-icon {
    border: 2px solid #0354CE;
    padding: 10px;
    border-radius: 12px;
    background: #fff;
}
.Admin.Customer .start-end-date.calender-date {
    display: flex;
    justify-content: end;
    margin-top: 10px;
}
.Admin.Customer .pagination-numb {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.Admin.Customer .pagination-numb li button{
    font-size: 20px !important;
    font-weight: 600 !important;
    
}
.Admin.Customer .pagination-numb ul{
    border-radius: 10px;
    background: #FFF;
    padding: 10px;
}

.Admin.Customer ul.product-detail{
    padding-left: 0 !important;
}
.Admin.Customer ul.product-detail li{
    list-style: none !important;
    margin-bottom: 6px;
    font-weight: 600;
}
.Admin.Customer .product-head p.product-block {
    text-align: center;
    font-size: 24px !important;
    font-weight: 600;
}
.Admin.Customer .list-item-name{
    display: flex;
    gap: 50px;
}
.Admin.Customer .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 1200px !important;
    margin: 0 auto !important;
}

.Admin.Customer .cancel-all {
    margin-top: 30px;
}
.Admin.Customer input#standard-required {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 3.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    padding: 28px;
    height: 61px;  
    width: 450px;

}

.Admin.Customer a.support-link {
    color: #0070F0;
}
.Admin.Customer .box-bg{
    border-radius: 11.129px;
border: 0.658px solid rgba(203, 239, 255, 0.16);
background-color: rgba(255, 255, 255, 0.76) !important;
backdrop-filter: blur(65.84379577636719px);
padding: 14px 70px !important;
}
.addressList .productId{
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap: 5%;
}
.addressList .productId .cmn-input-cls{
    width: 20%;
}
.addressList .productId .cmn-input-cls .MuiInputBase-root {
    width: 312px;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser, .AdminCustomerpopBOX.Deactivate.Reactivate.AddressPopup {
    max-width: none !important;
    height: 90%;
    overflow: auto;
}
::-webkit-input-placeholder {
text-align: center;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .info .Bill, .AddressPopup .info .Bill{
    border-radius: 15px;
    background: #BACBEF;
    padding:40px 20px;
}
.AddressPopup .info .Bill{
    border-radius: 15px;
    background: linear-gradient(180deg, #82AAFF 0%, rgba(255, 255, 255, 0.00) 100%);
    padding:40px 0px;
    /* width: 789px; */
}
/* .AddressPopup .Bill{
    width: 789px;
} */
.AdminCustomerpopBOX.Deactivate.AdminAddUser .info, .AddressPopup .info{
    margin: 25px 0px;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .billingInfo, .AdminCustomerpopBOX.Deactivate.AdminAddUser .rowOne, .AddressPopup .billingInfo{
    display: flex;
    justify-content: center;
    gap: 5%;
    flex-wrap: wrap;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .info .Bill .productId .cmn-input-cls, .AddressPopup .info .Bill .productId .cmn-input-cls{
    width: 100%;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .info .Bill .productId .cmn-input-cls .MuiInputBase-root, .AddressPopup .info .Bill .productId .cmn-input-cls .MuiInputBase-root{
    width: 100%;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .listSec, .AdminCustomerpopBOX.Deactivate.AdminAddUser .listTriRow,.AddressPopup .listSec, .AddressPopup .listTriRow{
    display: flex;
    gap: 2%;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTri .productId .cmn-input-cls, .AddressPopup .listTri .productId .cmn-input-cls{
    width: 100%;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTri .productId .cmn-input-cls .MuiInputBase-root, .AddressPopup .listTri .productId .cmn-input-cls .MuiInputBase-root{
    width: 100%;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTri .productId, .AddressPopup .listTri .productId{
    margin-top: 20px;
}

div#admin-customer-body-block-license-info .cmn-input-cls {
    margin-bottom: 0px;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .choice .warn{
    border-radius: 15px;
    background: #BACBEF;
    padding: 25px;
    width: 30%;
    text-align: left ;
    gap: 0;
    justify-content: center;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .choice .warn label{
    font-weight: 800;
}
.AddressPopup .choice .warn{
    border-radius: 15px;
background: #BAEFBF;
padding: 40px;
text-align: left !important;
}
.AdminCustomerpopBOX.Deactivate.AdminAddUser .mailActive{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.mailActive .field-box{
    width: 100%;
}
.mailActive .input-box {
    width: 450px;
    height: 134px;
}
.mailActive h2.modelTypo{
    margin-top: 30px;
    margin-bottom: 0 !important;
}
.edit{
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
    /* box-shadow: 0px 3.1px 0px 0px #FFF inset, 3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16); */
}
.create{
    border-radius: 4.519px;
border: 2.259px solid #FFC700;
background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
/* box-shadow: 0px 2.25939px 0px 0px #FFF inset, 2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16); */
}
 .addressList .add{
    max-width: 349px;
    margin: 15px auto;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.mailTitle{
    color: #000;
text-align: center;
font-size: 16px;
font-weight: 600;
line-height: 132%; /* 33px */
}
.mailValue{
    color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 132%;
}
.AddressPopup .addressList .mail{
text-align: center;
}
.AddressPopup .mail {
    text-align: left;
}
.AddressPopup .billingInfo .mail .mailValue {
    padding: 2px 10px;
}

.admin-customer-table-main table{
    display: table !important;
}

.Admin-customer-page .listbody {
    padding: 0;
}

.Admin-customer-page div#admin-customer-body-block {
    padding: 0 80px;
}

.AdminCustomerpopBOX.Deactivate.Reactivate.AddressPopup .choice {
    margin-top: 0px;
}

/* AdminCustomerpopBOX */

.AdminCustomerpopBOX{
    border-radius: 10px;
    background: #FFF;
    border: none !important;
    max-width: 859px;
    
}

.AdminCustomerpopBOX h2.modelTypo{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.AdminCustomerpopBOX .butOutLayer{
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 40px;
}
.AdminCustomerpopBOX .statusList {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    text-transform: capitalize;
}
.AdminCustomerpopBOX .start-end-date input {
    background: #fff;
    border: 1px solid grey;
}
.AdminCustomerpopBOX .butOutLayer Button{
    color: #FFF;
    font-size: 18px !important;;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 13px 35px;
    text-transform: capitalize;
}
.AdminCustomerpopBOX .butOutLayer Button.reset{
    border-radius: 4.519px;
    border: 2.259px solid #FFC700;
    background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
}
.AdminCustomerpopBOX .butOutLayer Button.apply{
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
}
.AdminCustomerpopBOX .start-end-date > .MuiStack-root {
    width: 362px;
}
.AdminCustomerpopBOX h2.modelTypo.renewal{
text-align: center;
}
.AdminCustomerpopBOX .cmn-input-cls{
    border-radius: 6.458px;
/* border: 1.615px solid #FFF; */
opacity: 0.6;
}

/*Edited by sankar*/

@media all and (max-width: 991.98px) {
    .AdminCustomerpopBOX .listOne, .popBOX .leftCheck, .AdminCustomerpopBOX .listTwo, .AdminCustomerpopBOX .listThree {
        display: flex;
        flex-direction: column;
        gap: 35px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
    }
    .AdminCustomerpopBOX .cmn-input-cls{
        border-radius: 6.458px;
    /* border: 1.615px solid #FFF; */
    opacity: 0.6;

    }
    .AdminCustomerpopBOX .butOutLayer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
        
        
        }
        .AdminCustomerpopBOX .butOutLayer Button.reset{
            border-radius: 4.519px;
            border: 2.259px solid #FFC700;
            background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
            margin-bottom: 2vh;
        }
        .AdminCustomerpopBOX.css-ymztxp{
            border-radius: 10px;
            background: #FFF;
            border: none !important;
            max-width: 454px;
            top: 70%;
        }
        .AdminCustomerpopBOX .textField{
            border-radius: 6.458px;
        background: #FFF !important;
        box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
        padding: 3px;
        padding-right: 0px !important;
        width: auto;
        }
        .AdminCustomerpopBOX.Deactivate.AdminAddUser .cmn-input-cls .MuiInputBase-root {
            width: 100% !important;
        }
}
@media (min-width: 1px) and (max-width: 575.98px) {
    .AdminCustomerpopBOX.css-ymztxp{
        border-radius: 10px;
        background: #FFF;
        border: none !important;
        max-width: 270px;
        top: 70%;
        
    }
}

@media all and (max-width: 767.98px) {

    .AdminCustomerpopBOX .cmn-input-cls{
        border-radius: 6.458px;
    /* border: 1.615px solid #FFF; */
    opacity: 0.6;

    width: auto;
    }
    .AdminCustomerpopBOX{
        border-radius: 10px;
        background: #FFF;
        border: none !important;
width: 340px !important;
margin-top: 50% !important;
        
    }
}
@media all and (max-width: 991.98px) {

    .AdminCustomerpopBOX{
        border-radius: 10px;
        background: #FFF;
        border: none !important;
width: 240px !important;
margin-top: 20%;
        
    }
}


.AdminCustomerpopBOX .listOne, .popBOX .leftCheck, .AdminCustomerpopBOX .listTwo, .AdminCustomerpopBOX .listThree {
    display: flex;
     gap: 35px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}
.AdminCustomerpopBOX .listThree {
    justify-content: space-between !important;
}
.AdminCustomerpopBOX .textField{
    border-radius: 6.458px;
background: #FFF !important;
box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
padding: 3px;
padding-right: 0px !important;
}
.AdminCustomerpopBOX .h2Variant{
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: capitalize;
    margin-bottom: 30px;
    text-align: center;
}

.AdminCustomerpopBOX.BlockPopup {
    padding: 50px !important;
    top: 53% !important;
}
.AdminCustomerpopBOX.BlockPopup .menu {
    text-align: right;
    margin-top: -36px;
    margin-bottom: 10px;
}
.AdminCustomerpopBOX.BlockPopup .whole-grid-value{
    max-width: 800px;
    margin: auto;
}
.AdminCustomerpopBOX.BlockPopup .item-name p {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.AdminCustomerpopBOX.BlockPopup .cancel-btn{
    border-radius: 4.519px !important;
    background: linear-gradient(134deg, #FFB800 -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    border: 2.259px solid #FFD600 !important;
    padding: 4px 25px !important;
    text-transform: capitalize;
    box-shadow: 0px 2.25939px 0px 0px #FFD600 inset, 2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16)
}
.AdminCustomerpopBOX.BlockPopup .product-detail {
    margin-top: 23px !important;
    background: none !important;
    margin: 0;
    padding: 0;
}
thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root, tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root{
white-space:nowrap;
}
.AdminCustomerpopBOX.BlockPopup ul.product-detail li{
    list-style: none !important;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
}
.AdminCustomerpopBOX.BlockPopup span.boxStyle {
    color:#2b8cff !important;
    width:10% !important;
    height:30% !important;
    background-color: transparent !important;
    border-bottom: #FFF !important;
}
.AdminCustomerpopBOX.BlockPopup span.boxStyle:hover{
    background-color: transparent !important;
}
.AdminCustomerpopBOX.BlockPopup span.boxStyle svg.MuiSvgIcon-root{
    border-color: transparent !important;
    border-radius: 5.667px !important;
    background: #FFF !important;
    box-shadow: 4.25px 4.25px 9.91667px 0px rgba(156, 156, 156, 0.48) inset, -4.25px -4.25px 9.91667px 0px #FFF inset !important;
}
.AdminCustomerpopBOX.BlockPopup span.boxStyle svg.MuiSvgIcon-root defs linearGradient stop{
    color: #2B8CFF !important;
}
.AdminCustomerpopBOX.BlockPopup .input-box {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    width: 450px;
    height: 134px;
}
.AdminCustomerpopBOX.BlockPopup .input-box .MuiFormControl-root.MuiTextField-root{
    border: 0 !important;
    outline: 0;
}
.AdminCustomerpopBOX.BlockPopup .disclamer-note p{
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.AdminCustomerpopBOX.BlockPopup .block-btn {
    border-radius: 4.519px;
    border: 2.259px solid #FF002E !important;
    background: linear-gradient(134deg, #FF002E -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    text-transform: capitalize;
    
}
.AdminCustomerpopBOX.BlockPopup .grid-direct{
    display: flex !important;
    align-items: inherit !important;
    margin-top: 25px;
}


/* .AdminCustomerpopBOX */


/* DEACTIVATIVE USER */

.Deactivate .brand{
text-align: center;
margin-bottom: 20px;
}
.Deactivate .reason{
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: capitalize;
    margin: 10px 0;
}
.Deactivate .warn {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    row-gap: 30px;
}

/*Edited by sankar*/
.css-79ws1d-MuiModal-root{
    overflow: scroll;
}

@media all and (max-width: 767.98px) {
    .Deactivate .warn {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5%;
        row-gap: 30px;
    }
}

.Deactivate .input-box {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    width: 450px;
    height: 134px;
    margin: 33px 0px 42px;
}
.Deactivate fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.Deactivate .butOutLayer Button.deactive {
    border-radius: 6.2px;
    border: 3.1px solid #E42B15;
    background: linear-gradient(134deg, #FF1B00 -26.79%, #756563 230.75%);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    text-transform: capitalize !important;
}

.Deactivate .butOutLayer Button.reactive{
    border-radius: 6.2px;
    border: 3.1px solid #B7F117;
    background: linear-gradient(134deg, #DFFF7F -26.79%, #ACB88A 230.75%);
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: capitalize;
}

.Reactivate{
    text-align: center;
}
.Reactivate .butOutLayer Button.reactive{
    border-radius: 6.2px;
    border: 3.1px solid #B7F117;
    background: linear-gradient(134deg, #DFFF7F -26.79%, #ACB88A 230.75%);
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: capitalize;
}
.Reactivate .warn{
    display: flex;
    justify-content: center !important;
    gap: 15% !important;
    }

    .Reactivate .statusList, .Deactivate .statusList {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
    }
    .Reactivate .input-box {
        margin: 20px auto 60px;
    }
    .field-box{
        width:100%;
    }
   .activate{
        background: transparent !important;
    }

    .AdminCustomerpopBOX.Deactivate.AdminAddUser .cmn-input-cls .MuiInputBase-root {
        width: 100%;
    }
    
    div#admin-customer-body-block > .MuiGrid-root, div#admin-customer-body-block-billing > .MuiGrid-root, #admin-customer-body-block-finance-info > .MuiGrid-root, #admin-customer-body-block-license-info > .MuiGrid-root {
        padding: 0 5px;
    }
    
    .AdminCustomerpopBOX.Deactivate-Reactivate.AdminCustomerpopBOX.Deactivate-Reactivate .h2Variant, .AdminCustomerpopBOX.Deactivate-Reactivate .reason {
        margin-bottom: 10px;
    }
    
    .AdminCustomerpopBOX.Deactivate-Reactivate .butOutLayer {
        margin-top: 10px !important;
    }
    
    .AdminCustomerpopBOX.Deactivate-Reactivate .input-box {
        margin-bottom: 20px;
    }

    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .h2Variant {
        margin-bottom: 5px;
    }
    
    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup p.reason {
        margin-bottom: 10px;
    }
    
    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .statusList {
        font-size: 15px;
    }
    
    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .warn {
        gap: 5px;
        max-width: 500px;
    }
    
    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .input-box {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .butOutLayer {
        margin-top: 20px;
    }
    th.MuiTableCell-root.MuiTableCell-body{
        text-align: center;
    }
    .listbody{
        width: 100%;
    }
    .admin-customer-contain{
        display:  block !important;
    }
    .uploade-files {
        text-align: center;
    }

    @media (max-width:1710.98px){
        .Admin.Customer .filter-btn button{
            margin-top: 10px !important;
        }
        .Admin.Customer input#standard-required{
            width: auto;
        }
        .addressList .productId .cmn-input-cls .MuiInputBase-root {
            width: 312px;
        }
        .AdminCustomerpopBOX.BlockPopup .whole-grid-value {
            gap: 20px;
        }
        .tablebody table {
            /* display: block !important; */
            overflow-x: scroll;
        }
        .filter-filed {
            display: flex;
            align-items: center;
        
        }
     
        .filter-btn button {
            margin-top: 10px !important;
        }
     
        .listbody{
            width: 100%;
        }
        .admin-customer-contain{
            display:  block !important;
        }
        .tableScroll::-webkit-scrollbar {
            height: 2px;
            width: 5px;
        }
        .AdminCustomerpopBOX.Deactivate.AdminAddUser .listSec, .AdminCustomerpopBOX.Deactivate.AdminAddUser .listTriRow, .AddressPopup .listSec, .AddressPopup .listTriRow{
            flex-direction: column;
        }
        .AdminCustomerpopBOX.Deactivate.AdminAddUser .choice .warn {
            width: 60%;
        }
        
    }

/*Edited by sankar*/

@media all and (max-width:991.98px) {
    .Deactivate .input-box { 
        width: 220px;
   }

   .filter-box {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
}
}
   
.accord-div p.MuiTypography-root.add-btn {
    background-color: #bacbef !important;
    padding: 10px;
    /* color: white; */
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
}
.accord-div .MuiAccordionSummary-content{
    justify-content: center;
}

.accord-div{
    margin-top: 25px;
}
.accord-div .MuiPaper-root.MuiPaper-elevation{
    box-shadow: none;
}


/* user popup table*/

.user-popup-table table, th, td {
    text-align: left !important;
}

.user-popup-table{
    text-align: center !important;
}

.user-popup-table button{
    margin-top: 25px;
}
.tablebody.admin-customer-table-main table tbody tr th {
    text-align: center !important;
}



.addsub-admin-div{
display:flex;
gap: 10px;
}