.extented-warrenty {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.warrenty-body{
    width: 752px;
    flex-shrink: 0;
    border-radius: 23.821px;
    background: #DFE5F4;
}

.watch-div{
display: flex;
justify-content: center;
margin-top: 25px;
}

.watch-id{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
text-align: center;
font-size: 23px;
font-weight: 700;
margin: 20px 0px;
align-items: center;
gap: 30px;
}

.watch-description{
    color: #000;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
max-width: 600px;
}

.map-component{
    width: 467.315px;
flex-shrink: 0;
}

.map-component iframe{
    width: -webkit-fill-available;
    border: none;
    height: inherit;

}

.Guarantee{
    width: 379.117px;
height: 73.717px;
border-radius: 13.164px;
border: 1.316px solid #000;
background: rgba(18, 237, 53, 0.20);
color: #000;
text-align: center;
display: flex;
align-items: center;
font-size: 18px;
font-weight: 700;
line-height: normal;
padding: 20px;
}
.Guarantees{
    width: 379.117px;
height: 73.717px;
border-radius: 13.164px;
border: 1.316px solid #f00;
background: rgba(244, 0, 14, 0.50);
color: #000;
justify-content: center;
text-align: center;
display: flex;
align-items: center;
font-size: 18px;
font-weight: 700;
line-height: normal;
padding: 20px;
}

.warrenty-records{
    width: 275.123px;
height: 25.011px;
color: #000;
text-align: center;
font-size: 18px;
font-weight: 600;
line-height: normal;

}

.warrenty-trns{
    width: 752px;
color: #000;
text-align: center;
font-size: 14px;
font-weight: 600;
line-height: normal;
}

.trns-details{
    width: 447.569px;
    padding: 15px;
}

.warrenty-display{
display: flex;
gap: 10px;
}

.reference-body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-div div{
    width: 30px;
    height: 30px;
    font-size: 15px;
}

.ref-text{
    color: #0070F0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
.ref-text2{
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
}
.trns-body{
    display: flex;
    justify-content: space-between;
}
.warrenty-hr{
    width: 400.838px;
    height: 0px;
    stroke-width: 1.053px;
  color: #0070F0;
    
}
.user-logo{
    width: 96.096px;
height: 17.113px;
color: #000;
text-align: center;
font-size: 15.332px;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
margin-top: 25px;
align-items: center;
gap: 5px;
}

.warrenty-body .back-arrow-left {
    margin: 20px;
}

@media (max-width:568px) {
    .Guarantees{
        width: 60%;
        font-size: 18px;
        /* word-break: break-all; */
    }
}

@media(max-width:767.98px){
    .warrenty-body{
        width: 100% !important;
    }
    .Guarantee{
        width: 80% !important;
    }
    .trns-body{
        flex-direction: column;
          justify-content: center !important;
          align-items: center;
          gap: 15px;
      }
      .watch-div img {
        width: 250px !important;
    }
}