.popBOX.BlockPopup.qrproduct-pop {
    width: 645px !important;
    height: 75vh;
    overflow: auto;
    backdrop-filter: none !important;
}

p.product-code-head {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

p.pro-id {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}

.qr-product-scan {
    text-align: center;
}

.qr-product-scan.genship svg {
    width: 200px;
    /* height: 200px; */
}

.qr-product-scan img {
    width: 40%;
}

.product-img-bag {
    display: flex;
    gap: 35px;
    margin-top: 30px;
}

p.product-client-id {
    font-size: 20px;
    font-weight: 700;
}

p.product-client-id span {
    color: #0070F0;
    font-size: 20px;
    font-weight: 700;
}

.category-sctn {
    text-align: center;
    border-radius: 15px;
    border: 2px solid #45C459;
    background: #FFF;
    max-width: 344px;
    margin: 50px auto;
    padding: 33px 23px 47px 27px;
}

.approve-button {
    border-radius: 2.921px;
    border: 1.461px solid #00FF29;
    background: linear-gradient(134deg, #1EE13D -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    padding: 6px 25px;
}

a.url-link {
    text-decoration: none;
    color: #0070F0;
}

.action-part {
    text-align: center;
}

p.action-head {
    font-size: 20px;
    font-weight: 700;
}

p.action-part-cnt {
    font-size: 15px;
    font-weight: 400;
}

p.action-info {
    color: #E01818;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    max-width: 414px;
    margin: 0 auto;
}

.certificate-btn button {
    border-radius: 4.519px;
    border: 2.259px solid #00FF47;
    background: linear-gradient(134deg, #00FF57 -26.79%, #8B8B8B 230.75%);
    width: 50%;
    color: #fff !important;
}

.certificate-btn {
    text-align: center;
    margin-bottom: 5px;
}

.generate-btns {
    margin-top: 35px;
}

.certificate-btn.qr-code button {
    border-radius: 4.519px;
    border: 2.259px solid rgba(235, 255, 0, 0.36);
    background: linear-gradient(134deg, #FFD600 -26.79%, #8B8B8B 230.75%) !important;
}

.certificate-btn.print-btns button {
    border-radius: 4.519px;
    border: 2.259px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
}

p.email-send {
    font-size: 15px;
    font-weight: 700;
    max-width: 162px;
}

.email-certificate {
    display: flex;
    justify-content: space-around;
}

.email-input-box {
    margin-top: 35px;
    border-radius: 20px;
    border: 1px solid #0070F0;
    background: #FFF;
    padding: 30px;
}

.input-txt-box {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    border: none !important;
}

p.default-para {
    font-size: 13px;
}

.default-acct {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.successfull-certificate {
    text-align: center;
    margin-top: 40px;
}

.successfull-certificate {
    text-align: center;
    margin-top: 40px;
    border-radius: 20px;
    border: 1px solid #0070F0;
    background: #FFF;
    padding: 30px;
}

p.success-msg {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.red-tick {
    color: #FF0000;
    font-size: 25px !important;
}

.green-tick {
    font-size: 25px !important;
    color: #58A626;
}

.popBOX.BlockPopup.rejectpop {
    border-radius: 20px;
    background: rgba(38, 38, 38, 0.40) !important;
    backdrop-filter: none !important;
    height: 77%;
    overflow: auto;
}

p.reject-head {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

p.reason-reject {
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.check-list ul {
    list-style-type: none;
    color: #fff;
    font-size: 20px;
    padding-left: 74px;
}

.box-border path {
    color: #0070F0;
}

.check-list {
    margin-top: 50px;
}

.check-list ul li {
    margin-bottom: 15px;
}

button.decline {
    border-radius: 4.519px;
    border: 2.259px solid #FFC700;
    background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
    color: #fff;
    padding: 8px 27px;
}

.decline-cancel-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 25px;
}

button.cancel {
    border-radius: 6.2px;
    border: 3.1px solid #FF1B00;
    background: linear-gradient(134deg, #E52A13 -26.79%, #C53A2A 230.75%);
    color: #fff;
    padding: 8px 27px;
}

.text-box {
    text-align: center;
}

.box-size {
    width: 600px;
    padding: 30px !important;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 5.26495px 5.26495px 12.28487px 0px rgba(156, 156, 156, 0.48) inset, -5.26495px -5.26495px 12.28487px 0px #FFF inset;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

button.approve {
    border-radius: 4.519px;
    border: 2.259px solid #00FF29;
    background: linear-gradient(134deg, #1EE13D -26.79%, #4EBC60 230.75%);
    padding: 8px 18px;
    color: #fff;
}

.popBOX.BlockPopup.rejectpop.approve {
    height: 40%;
    padding: 80px !important;
}

p.reason-reject.approval-txt {
    max-width: 760px;
    margin: 0 auto;
    padding-bottom: 30px;
}

p.scan-blockchain-para {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.address-detail p.scan-blockchain-para {
    text-align: left !important;
}

.address-detail p.scan-blockchain-para span {
    font-size: 26px !important;
    font-weight: 400 !important;
    word-wrap: break-word;
}

.popBOX.BlockPopup.scanpopup {
    backdrop-filter: none !important;
}

p.view-over {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 9px;
}

.head-over {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid;
}

.overview-box-outer {
    border-radius: 15px;
    background: #D9D9D9;
    padding: 10px 41px 15px 46px;
    width: 78%;
    height: 100%;
}

.value-overview span {
    color: #0070F0;
}

.trans-tab-table {
    margin-top: 50px;
    background: #D9D9D9;
}

.name-part.Mui-selected {
    color: #000 !important;
    font-weight: 500 !important;
    border-radius: 15px;
    border: 1px solid #0070F0;
}

.name-part {
    color: #000 !important;
    font-weight: 500 !important;
    border-radius: 15px;
}

.body-bg {
    background: transparent !important;
    /* border-bottom: 2px solid #0070F0 !important; */
    /* edited by Sankar */
    font-size: 15px !important;
    font-weight: 600 !important;
    /* padding: 10px 0 !important; */
}

.trans-tab-table .css-19kzrtu {
    padding: 0 !important;
}

.tab-head-part {
    padding: 30px;
}

.head-bg {
    background: rgba(174, 192, 236, 0.80) !important;
    color: #000 !important;
    text-align: center !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    padding: 25px 30px !important;
}

.info-product-scan img {
    width: 30%;
    background: #ffffff;
    padding: 10px;
    border-radius: 15px;
}

.info-product-scan {
    text-align: center;
}

p.lv-bag {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

p.lv-made {
    color: #0070F0;
    font-size: 21px;
}

.special-bag {
    margin-top: 100px;
}

.productinfo-img-bag {
    display: flex;
    gap: 35px;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
}



.popBOX.BlockPopup.infopopup {
    width: 1420px !important;
    backdrop-filter: none !important;
}

.category-sctn.info-category {
    border-radius: 15px;
    border: 2px solid #DA952D;
    background: #FFF;
}

.category-sctn.info-category p.product-client-id span {
    color: #0070F0;
    font-size: 17px;
    font-weight: 700;
}

.pending-appr-button {
    border-radius: 2.921px;
    border: 1.461px solid #FFC700;
    background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
    color: #000 !important;
    padding: 12px;
}

.approve-tick {
    border-radius: 2.921px;
    border: 1.461px solid #47C15B;
    background: linear-gradient(134deg, #40C755 -26.79%, #579E62 230.75%);
    color: #000 !important;
    padding: 12px;
}

.pend-approved-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.approved-btn {
    text-align: center;
}

.wrong-tick {
    border-radius: 2.921px;
    border: 1.461px solid #F00;
    background: linear-gradient(134deg, #F00 -26.79%, #8B8B8B 230.75%);
    color: #000 !important;
    padding: 12px;
}

.buttons-approve {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ean-info {
    display: flex;
    gap: 50px;
    overflow: auto;
}

p.product-info-title {
    font-weight: 700;
    font-size: 19px;
}

.warranty-extension {
    display: flex;
    justify-content: space-around;
    border-radius: 15px;
    background: #D9D9D9;
}

p.blue-msg {
    color: #4E8DEB;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.edit-info {
    border-radius: 4.519px;
    border: 2.259px solid #FFC700;
    background: linear-gradient(134deg, #F90 -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    padding: 10px 24px !important;
}

.edit-doenlod-info {
    margin-top: 50px;
}

.down-info {
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    padding: 10px 24px !important;
}
.down-info2 {
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #0065FF -26.79%, #8B8B8B 230.75%);
    color: #fff !important;
    padding: 9px 24px !important;
}

.edit-doenlod-info {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
}

.cristin-para {
    max-width: 500px !important;
}

.popBOX.BlockPopup.requpdate .reject-head,
.popBOX.BlockPopup.requpdate p.reason-reject,
.popBOX.BlockPopup.requpdate .check-list ul {
    color: #000 !important;
}

.popBOX.BlockPopup.requpdate {
    backdrop-filter: none !important;
}

.req-up {
    background: rgba(235, 255, 0, 1) !important;
    border-radius: 6.2px;
    border: 3.1px solid rgba(235, 255, 0, 1);
    color: #000 !important;
}

.popBOX.BlockPopup.requpdate.block .check-list {
    display: flex;
    justify-content: center;
}

.popBOX.BlockPopup {
    height: 78vh;
    overflow: auto;
}

p.red-alert {
    color: #F00;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

.popBOX.BlockPopup.blockproduct {
    height: 40%;
    padding: 80px;
}

p.block-head {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

Table.tableScroll {
    overflow-x: auto;
    display: block;
}

.tab-head-part .MuiTabs-scroller {
    overflow: auto !important;
}

.reactive-size-check {
    max-width: 186px;
    margin: 0 auto;
}

.size-row {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.other-size {
    text-align: center;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.40);
    font-weight: 700;
}

.req-up.print {
    padding: 7px 25px;
}

.eye-direct {
    display: flex;
    gap: 4px;
    align-items: center;
}


@media (max-width:567px) {

    p.action-info {
        font-size: 13px;
    }

    p.action-part-cnt {
        font-size: 13px;
        font-weight: 400;
    }

    input#outlined-password-input {
        height: 0.437rem;
    }

    p.default-para {
        font-size: 10px;
    }

    p.reject-head {
        font-size: 23px !important;
    }

    p.reason-reject {
        font-size: 16px !important;
    }

    .check-list ul {
        font-size: 12px !important;
    }

    p.product-code-head {
        font-size: 28px !important;
    }

    p.pro-id {
        font-size: 16px !important;
    }

    p.product-client-id {
        font-size: 12px !important;
    }

    p.product-client-id span {
        font-size: 12px !important;
    }

    .approve-button {
        font-size: 10px !important;
        text-align: center !important;
    }

    .certificate-btn button {
        width: 80% !important;
        font-size: 12px !important;
    }

    .pending-appr-button {
        padding: 8px;
        font-size: 12px;
    }

    .down-info {
        padding: 8px 18px !important;
        font-size: 12px !important;
    }

    p.blue-msg {
        font-size: 14px;
    }

    p.product-info-title {
        font-size: 15px;
    }
}



@media(max-width: 767.98px) {
    .popBOX.BlockPopup.qrproduct-pop {
        width: 70% !important;

    }

    .ean-info {
        flex-direction: column;
        gap: 0px !important;
    }


    .product-img-bag {
        /* display: block; */
        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    p.pro-id {
        font-size: 22px;
    }

    .email-certificate {
        flex-direction: column !important;
    }

    input#outlined-password-input {
        width: 100% !important;
    }

    .box-size {
        width: auto;
    }

    .check-list ul {
        padding-left: 0;
    }



    .productinfo-img-bag {
        flex-direction: column !important;
        align-items: center !important;
    }

    .edit-doenlod-info {
        display: block;
        text-align: center;

    }

    .edit-doenlod-info button {
        margin-bottom: 10px;
    }

    .overview-box-outer {
        width: auto;
    }

    .head-over {
        display: block;
    }

    .trans-tab-table .css-19kzrtu {
        padding: 10px !important;
    }

    .warranty-extension {
        display: block;
        text-align: center;
        padding: 10px;
    }
}

@media(min-width: 768px) and (max-width: 991.98px) {

    /* .popBOX.BlockPopup.infopopup {
        width: 610px !important;
    } */

    .warranty-extension {
        display: block;
        text-align: center;
        padding: 10px;
    }

    .check-list {
        text-align: left;
    }
}


@media (max-width:1599px) {
    .popBOX.BlockPopup.infopopup {
        width: 70% !important;
    }
}