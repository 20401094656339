.Admin.Customer .listbody {
  padding: 50px 0;
}

.Admin.Customer .filter-btn button {
  padding: 8px 24px;
  gap: 4px;
}

.info-bag {
  /* height: 200px; */
  width: 50% !important;
  border-radius: 5px;
}

.info-bag img {
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Admin.Customer .filterBtn {
  width: auto;
  font-size: 0.875rem !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5.313px;
  border: 2.656px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
}

.Admin.Customer .filter-btn .filterBtn {
  width: auto;
  font-size: 0.875rem !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5.313px;
  border: 2.656px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
}

.Admin.Customer .head-bg {
  background: rgba(174, 192, 236, 0.8) !important;
  color: #000 !important;
  text-align: center !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.Admin.Customer .filter-box {
  display: flex;
  gap: 20px;
}

.Admin.Customer .tablebody {
  padding-top: 30px;
  /* overflow: auto; */
}

.Admin.Customer Table.tableScroll {
  overflow-x: auto;
  display: block;
}

.Admin.Customer .save-icon svg {
  color: #fff;
}

.Admin.Customer .body-bg {
  background: rgba(255, 255, 255, 0.76);
  /* border-bottom: 2px solid #0070F0 !important; */
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 5px;
}

.Admin.Customer .icon-space {
  display: flex;
}

.icon-space svg {
  color: #000 !important;
}

.Admin.Customer .filter-btn {
  margin-top: 60px;
}

.Admin.Customer .filter-color {
  color: #000 !important;
}

.Admin.Customer .contain-width {
  max-width: 93%;
  margin: 0 auto;
}

.Admin.Customer .filter-filed {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Admin.Customer .download-keys {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.Admin.Customer .save-icon {
  border: 2px solid #0354ce;
  padding: 10px;
  border-radius: 12px;
  background: #0070f0;
}

.Admin.Customer .print-icon {
  border: 2px solid #0354ce;
  padding: 10px;
  border-radius: 12px;
  background: #fff;
}

.Admin.Customer .start-end-date.calender-date {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.Admin.Customer .pagination-numb {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pagination-numbs {
  display: flex;
  justify-content: center;
  margin: 30px;
  flex-direction: column;
  /* align-items: center; */
}

.Admin.Customer .pagination-numb li button {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.Admin.Customer .pagination-numb ul {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

.Admin.Customer ul.product-detail {
  padding-left: 0 !important;
}

.Admin.Customer ul.product-detail li {
  list-style: none !important;
  margin-bottom: 6px;
  font-weight: 600;
}

.Admin.Customer .product-head p.product-block {
  text-align: center;
  font-size: 24px !important;
  font-weight: 600;
}

.Admin.Customer .list-item-name {
  display: flex;
  gap: 50px;
}

.Admin.Customer .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.Admin.Customer .cancel-all {
  margin-top: 30px;
}

.Admin.Customer input#standard-required {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 3.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  padding: 28px;
  height: 61px;
  width: 450px;
}

.Admin.Customer a.support-link {
  color: #0070f0;
}

.Admin.Customer .box-bg {
  border-radius: 11.129px;
  border: 0.658px solid rgba(203, 239, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.76) !important;
  backdrop-filter: blur(65.84379577636719px);
  padding: 14px 70px !important;
}

.addressList .productId {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.addressList .productId .cmn-input-cls {
  width: 20%;
}

.addressList .productId .cmn-input-cls .MuiInputBase-root {
  width: 312px;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser,
.AdminCustomerpopBOX.Deactivate.Reactivate.AddressPopup {
  max-width: none !important;
  height: 90%;
  overflow: auto;
}

::-webkit-input-placeholder {
  text-align: center;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .info .Bill,
.AddressPopup .info .Bill {
  border-radius: 15px;
  background: #bacbef;
  padding: 40px 20px;
}

.AddressPopup .info .Bill {
  border-radius: 15px;
  background: linear-gradient(180deg, #82aaff 0%, rgba(255, 255, 255, 0) 100%);
  padding: 40px 0px;
  /* width: 789px; */
}

/* .AddressPopup .Bill{
    width: 789px;
} */
.AdminCustomerpopBOX.Deactivate.AdminAddUser .info,
.AddressPopup .info {
  margin: 25px 0px;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .billingInfo,
.AdminCustomerpopBOX.Deactivate.AdminAddUser .rowOne,
.AddressPopup .billingInfo {
  display: flex;
  justify-content: center;
  gap: 5%;
  flex-wrap: wrap;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser
  .info
  .Bill
  .productId
  .cmn-input-cls,
.AddressPopup .info .Bill .productId .cmn-input-cls {
  width: 100%;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser
  .info
  .Bill
  .productId
  .cmn-input-cls
  .MuiInputBase-root,
.AddressPopup .info .Bill .productId .cmn-input-cls .MuiInputBase-root {
  width: 100%;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .listSec,
.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTriRow,
.AddressPopup .listSec,
.AddressPopup .listTriRow {
  display: flex;
  gap: 2%;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTri .productId .cmn-input-cls,
.AddressPopup .listTri .productId .cmn-input-cls {
  width: 100%;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser
  .listTri
  .productId
  .cmn-input-cls
  .MuiInputBase-root,
.AddressPopup .listTri .productId .cmn-input-cls .MuiInputBase-root {
  width: 100%;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .listTri .productId,
.AddressPopup .listTri .productId {
  margin-top: 20px;
}

div#admin-customer-body-block-license-info .cmn-input-cls {
  margin-bottom: 0px;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .choice .warn {
  border-radius: 15px;
  background: #bacbef;
  padding: 40px;
  width: 30%;
  text-align: center;
  gap: 0;
}

.AddressPopup .choice .warn {
  border-radius: 15px;
  background: #baefbf;
  padding: 40px;
  text-align: left !important;
}

.AdminCustomerpopBOX.Deactivate.AdminAddUser .mailActive {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mailActive .field-box {
  width: 100%;
}

.mailActive .input-box {
  width: 450px;
  height: 134px;
}

.mailActive h2.modelTypo {
  margin-top: 30px;
  margin-bottom: 0 !important;
}

.edit {
  border-radius: 6.2px;
  border: 3.1px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
  /* box-shadow: 0px 3.1px 0px 0px #FFF inset, 3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16); */
}

.create {
  border-radius: 4.519px;
  border: 2.259px solid #ffc700;
  background: linear-gradient(134deg, #f90 -26.79%, #8b8b8b 230.75%);
  /* box-shadow: 0px 2.25939px 0px 0px #FFF inset, 2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16); */
}

.addressList .add {
  max-width: 349px;
  margin: 20px auto 30px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.mailTitle {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 132%;
  /* 33px */
}

.mailValue {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
}

.AddressPopup .addressList .mail {
  text-align: center;
}

.AddressPopup .mail {
  text-align: left;
}

.AddressPopup .billingInfo .mail .mailValue {
  padding: 2px 10px;
}

.admin-customer-table-main table {
  display: table !important;
}

.Admin-customer-page .listbody {
  padding: 0;
}

/* .Admin-customer-page div#admin-customer-body-block {
    padding: 0 80px;
} */

.AdminCustomerpopBOX.Deactivate.Reactivate.AddressPopup .choice {
  margin-top: 0px;
}

/* AdminCustomerpopBOX */

.AdminCustomerpopBOX {
  border-radius: 25px;
  background: #fff;
  border: none !important;
  max-width: 859px;
}

.AdminCustomerpopBOX h2.modelTypo {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.AdminCustomerpopBOX .butOutLayer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.AdminCustomerpopBOX .statusList {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
}

.AdminCustomerpopBOX .start-end-date input {
  background: #fff;
  border: 1px solid grey;
}

.AdminCustomerpopBOX .butOutLayer Button {
  color: #fff;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 13px 35px;
  text-transform: capitalize;
}

.AdminCustomerpopBOX .butOutLayer Button.reset {
  border-radius: 4.519px;
  border: 2.259px solid #ffc700;
  background: linear-gradient(134deg, #f90 -26.79%, #8b8b8b 230.75%);
}

.AdminCustomerpopBOX .butOutLayer Button.apply {
  border-radius: 6.2px;
  border: 3.1px solid #0065ff;
  background: linear-gradient(134deg, #0065ff -26.79%, #8b8b8b 230.75%);
}

.AdminCustomerpopBOX .start-end-date > .MuiStack-root {
  width: 362px;
}

.AdminCustomerpopBOX h2.modelTypo.renewal {
  text-align: center;
}

.AdminCustomerpopBOX .cmn-input-cls {
  border-radius: 6.458px;
  /* border: 1.615px solid #FFF; */
  opacity: 0.6;
}

.AdminCustomerpopBOX .listOne,
.popBOX .leftCheck,
.AdminCustomerpopBOX .listTwo,
.AdminCustomerpopBOX .listThree {
  display: flex;
  gap: 35px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.AdminCustomerpopBOX .listThree {
  justify-content: space-between !important;
}

.AdminCustomerpopBOX .textField {
  border-radius: 6.458px;
  background: #fff !important;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  padding: 3px;
  padding-right: 0px !important;
}

.AdminCustomerpopBOX .h2Variant {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: capitalize;
  margin-bottom: 30px;
  text-align: center;
}

.AdminCustomerpopBOX.BlockPopup {
  padding: 50px !important;
  top: 53% !important;
}

.AdminCustomerpopBOX.BlockPopup .menu {
  text-align: right;
  margin-top: -36px;
  margin-bottom: 10px;
}

.AdminCustomerpopBOX.BlockPopup .whole-grid-value {
  max-width: 800px;
  margin: auto;
}

.AdminCustomerpopBOX.BlockPopup .item-name p {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AdminCustomerpopBOX.BlockPopup .cancel-btn {
  border-radius: 4.519px !important;
  background: linear-gradient(134deg, #ffb800 -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
  border: 2.259px solid #ffd600 !important;
  padding: 4px 25px !important;
  text-transform: capitalize;
  box-shadow: 0px 2.25939px 0px 0px #ffd600 inset,
    2.25939px 9.03758px 27.11272px 0px rgba(0, 0, 0, 0.16);
}

.AdminCustomerpopBOX.BlockPopup .product-detail {
  margin-top: 23px !important;
  background: none !important;
  margin: 0;
  padding: 0;
}

thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root,
tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
  white-space: nowrap;
}

.AdminCustomerpopBOX.BlockPopup ul.product-detail li {
  list-style: none !important;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 15px;
}

.AdminCustomerpopBOX.BlockPopup span.boxStyle {
  color: #2b8cff !important;
  width: 10% !important;
  height: 30% !important;
  background-color: transparent !important;
  border-bottom: #fff !important;
}

.AdminCustomerpopBOX.BlockPopup span.boxStyle:hover {
  background-color: transparent !important;
}

.AdminCustomerpopBOX.BlockPopup span.boxStyle svg.MuiSvgIcon-root {
  border-color: transparent !important;
  border-radius: 5.667px !important;
  background: #fff !important;
  box-shadow: 4.25px 4.25px 9.91667px 0px rgba(156, 156, 156, 0.48) inset,
    -4.25px -4.25px 9.91667px 0px #fff inset !important;
}

.AdminCustomerpopBOX.BlockPopup
  span.boxStyle
  svg.MuiSvgIcon-root
  defs
  linearGradient
  stop {
  color: #2b8cff !important;
}

.AdminCustomerpopBOX.BlockPopup .input-box {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  width: 450px;
  height: 134px;
}

.AdminCustomerpopBOX.BlockPopup
  .input-box
  .MuiFormControl-root.MuiTextField-root {
  border: 0 !important;
  outline: 0;
}

.AdminCustomerpopBOX.BlockPopup .disclamer-note p {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.AdminCustomerpopBOX.BlockPopup .block-btn {
  border-radius: 4.519px;
  border: 2.259px solid #ff002e !important;
  background: linear-gradient(134deg, #ff002e -26.79%, #8b8b8b 230.75%);
  color: #fff !important;
  text-transform: capitalize;
}

.AdminCustomerpopBOX.BlockPopup .grid-direct {
  display: flex !important;
  align-items: inherit !important;
  margin-top: 25px;
}

/* .AdminCustomerpopBOX */

/* DEACTIVATIVE USER */

.Deactivate .brand {
  text-align: center;
  margin-bottom: 20px;
}

.Deactivate .reason {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: capitalize;
  margin-top: 0;
}

.Deactivate .warn {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  row-gap: 30px;
}

.Deactivate .input-box {
  border-radius: 6.458px;
  background: #fff;
  box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset,
    -4.84375px -4.84375px 11.30208px 0px #fff inset;
  width: 450px;
  height: 134px;
  margin: 33px 0px 42px;
}

.Deactivate fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Deactivate .butOutLayer Button.deactive {
  border-radius: 6.2px;
  border: 3.1px solid #e42b15;
  background: linear-gradient(134deg, #ff1b00 -26.79%, #756563 230.75%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  text-transform: capitalize !important;
}

.Reactivate {
  text-align: center;
}

.Reactivate .butOutLayer Button.reactive {
  border-radius: 6.2px;
  border: 3.1px solid #b7f117;
  background: linear-gradient(134deg, #dfff7f -26.79%, #acb88a 230.75%);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: capitalize;
}

.Reactivate .warn {
  display: flex;
  justify-content: center !important;
  gap: 15% !important;
}

.Reactivate .statusList,
.Deactivate .statusList {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.Reactivate .input-box {
  margin: 20px auto 60px;
}

.field-box {
  width: 100%;
}

.activate {
  background: transparent !important;
}

/*.AdminCustomerpopBOX.Deactivate.AdminAddUser .cmn-input-cls .MuiInputBase-root {
    width: 100%;
}*/

div#admin-customer-body-block > .MuiGrid-root,
div#admin-customer-body-block-billing > .MuiGrid-root,
#admin-customer-body-block-finance-info > .MuiGrid-root,
#admin-customer-body-block-license-info > .MuiGrid-root {
  padding: 0 5px;
}

.AdminCustomerpopBOX.Deactivate-Reactivate.AdminCustomerpopBOX.Deactivate-Reactivate
  .h2Variant,
.AdminCustomerpopBOX.Deactivate-Reactivate .reason {
  margin-bottom: 10px;
}

.AdminCustomerpopBOX.Deactivate-Reactivate .butOutLayer {
  margin-top: 10px !important;
}

.AdminCustomerpopBOX.Deactivate-Reactivate .input-box {
  margin-bottom: 20px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .h2Variant {
  margin-bottom: 5px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup p.reason {
  margin-bottom: 10px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .statusList {
  font-size: 15px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .warn {
  gap: 5px;
  max-width: 500px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .input-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.AdminCustomerpopBOX.Deactivate.Deactivate-user-popup .butOutLayer {
  margin-top: 20px;
}

.AdminCustomerpopBOX.AdminCustomerpopBOX-product-filter .MuiInputBase-root {
  /* width: 100%; */
}

.statusList-outer-list-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.qrbar-code {
  display: flex;
  align-items: center;
}

.qrbar-code img {
  height: 35px;
}

button.activate.qr-detail svg {
  font-size: 30px !important;
  color: #000;
}

.padding-lr-0px-80px {
  padding: 0 80px;
}

.tablebody.admin-customer-table-main.admin-pdts-table-main-block table {
  overflow-x: auto !important;
  border-radius: 20px !important;
  background: #fff;
  /* display: block !important; */
}

.admin-pdts-table-main-block Button.activate {
  gap: 10px;
}

.admin-pdts-table-main-block a {
  text-transform: capitalize !important;
  width: 40px;
  height: 40px;
}

.admin-pdts-table-main-block .wdwtc {
  display: flex;
  gap: 10px;
  align-items: center;
  /* margin-left: 10px; */
}

.admin-pdts-table-main-block a img {
  width: 35px;
}

.admin-pdts-table-main-block .wdwtc a.cancel,
.admin-pdts-table-main-block .wdwtc a.approve {
  border: none !important;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.admin-pdts-table-main-block .thumbalin {
  text-align: center;
}

.wdwtc a.approve {
  border-radius: 4.519px;
  border: none !important;
  background: linear-gradient(134deg, #1ee13d -26.79%, #4ebc60 230.75%);
  padding: 8px 18px;
  color: #fff;
}

.wdwtc a.cancel {
  border-radius: 6.2px;
  border: none !important;
  background: linear-gradient(134deg, #e52a13 -26.79%, #c53a2a 230.75%);
  color: #fff;
  padding: 8px 27px;
}

.admin-pdts-table-main-block .icon-space {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDialog-paperScrollPaper table thead tr th {
  color: #fff;
  text-align: left;
}

.MuiDialog-paperScrollPaper table tbody tr th {
  max-width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MuiDialog-paperScrollPaper table tbody tr th {
  border-radius: 0;
  background: #cfe1ff !important;
  text-align: left;
}

.MuiDialog-paperScrollPaper table tbody {
  background: #cfe1ff !important;
  /* display: block; */
}

.MuiDialog-paperScrollPaper table thead {
  /* display: block; */
  background: #0070f0 !important;
  border-radius: 0;
}

.MuiPaper-rounded::-webkit-scrollbar-thumb {
  background: #4c535f;
}

.MuiPaper-rounded::-webkit-scrollbar-track {
  background: #b5b5b5;
}

.MuiPaper-rounded::-webkit-scrollbar {
  height: 5px;
  width: 3px;
}

.two-btn {
  display: flex;
  gap: 25px;
  margin: 15px 30px;
}

.two-btn button {
  width: 100px;
}

.two-btn .add-btn,
.two-btn .add-btn:hover {
  border-radius: 4.802px;
  border: 2.401px solid #0065ff;
  background: #0065ff;
  color: #fff !important;
  gap: 10px;
  padding: 10px;
}

td.loading-center {
  border: none;
}

@media all and (max-width: 575.98px) {
  /*Edited by sankar */
  .padding-lr-0px-80px {
    padding: 0 20px;
  }
}

@media all and (max-width: 1199.98px) {
  /*Edited by sankar */
  .tablebody.admin-customer-table-main.admin-pdts-table-main-block table {
    overflow-x: auto !important;
    /* display: block ; */
  }
}

@media (max-width: 991.98px) {
  .Admin.Customer .filter-btn button {
    margin-top: 10px !important;
  }

  .Admin.Customer input#standard-required {
    width: auto;
  }

  .addressList .productId .cmn-input-cls .MuiInputBase-root {
    width: 312px;
  }

  .AdminCustomerpopBOX.BlockPopup .whole-grid-value {
    gap: 20px;
  }

  .statusList-outer-list-flex {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .qrbar-code {
    display: flex;
    align-items: center;
  }

  .qrbar-code img {
    height: 35px;
  }

  button.activate.qr-detail svg {
    font-size: 30px !important;
    color: #000;
  }

  .admin-pdts-table-main-block Button.activate {
    gap: 10px;
  }

  .admin-pdts-table-main-block a {
    text-transform: capitalize !important;
  }

  .admin-pdts-table-main-block .wdwtc {
    display: flex;
    gap: 10px;
  }

  .admin-pdts-table-main-block .wdwtc a.cancel,
  .admin-pdts-table-main-block .wdwtc a.approve {
    border: none !important;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .admin-pdts-table-main-block .thumbalin {
    text-align: center;
  }

  .wdwtc a.approve {
    border-radius: 4.519px;
    border: none !important;
    background: linear-gradient(134deg, #1ee13d -26.79%, #4ebc60 230.75%);
    padding: 8px 18px;
    color: #fff;
  }

  .wdwtc a.cancel {
    border-radius: 6.2px;
    border: none !important;
    background: linear-gradient(134deg, #e52a13 -26.79%, #c53a2a 230.75%);
    color: #fff;
    padding: 8px 27px;
  }

  .admin-pdts-table-main-block .icon-space {
    display: flex;
    align-items: center;
  }

  .loaders-load {
    padding: 0 20px;
  }

  .loaders-load img {
    width: 100% !important;
  }
}

.profile-image-outer.excel-file-upload .upload-image-block {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.profile-image-outer.excel-file-upload
  .profile-image.profile-image-design-block {
  border-radius: 0;
  overflow: hidden;
  margin-top: 10px;
  padding: 0px;
  background: transparent;
}

.profile-image-outer.excel-file-upload .edit-image-btn {
  position: absolute;
  bottom: 0px;
  width: 96%;
  padding: 10px;
  background: transparent;
  border-radius: 0 0 20px 20px;
  left: -3px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .Admin.Customer .filter-btn button {
    margin-top: 10px !important;
  }

  .Admin.Customer input#standard-required {
    width: auto;
  }

  .addressList .productId .cmn-input-cls .MuiInputBase-root {
    width: 312px;
  }

  .AdminCustomerpopBOX.BlockPopup .whole-grid-value {
    gap: 20px;
  }

  .generate-product {
    flex-direction: column;
  }

  .auth-seller-flex {
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center !important;
  }
}

/*Edited by sankar*/
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.MuiGrid-grid-xl-12.filter-main-seller.css-1idn90j-MuiGrid-root {
  margin-top: 120px !important;
  margin-right: 100px !important;
  margin-left: 70px !important;
}

@media all and (max-width: 991.98px) {
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.MuiGrid-grid-xl-12.filter-main-seller.css-1idn90j-MuiGrid-root {
    margin-top: 120px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
}

@media all and (max-width: 350.98px) {
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.MuiGrid-grid-xl-12.filter-main-seller.css-1idn90j-MuiGrid-root {
    margin-top: 120px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.edit-image-btn1 {
  position: absolute;
  bottom: 4px;
  width: 96%;
  padding: 10px;
  background: transparent;
  border-radius: 0 0 20px 20px;
  left: -3px;
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.download {
  text-align: center;
}

.download h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.download span {
  font-size: 14px;
  font-weight: 600;
}

.download p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
}

.upload {
  text-align: center;
}

.upload h6 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.upload span {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.upload p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
}

.popBOX.add-seller.product-upload-popup {
  width: 800px !important;
  height: auto !important;
}

.popBOX.add-seller.product-upload-popup .headTitle {
  margin-bottom: 50px !important;
}

.premission-access {
  height: calc(100vh - 350px);
}

.blurs {
  opacity: 0.1;
}

.loaders-load {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loaders-load img {
  width: auto;
}

.certificate-box {
  display: flex;
  justify-content: center;
}

.warrenty-body.admin-owner {
  background: transparent;
}

.generate-product {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.generate-product .certificate-btn.qr-code button {
  width: 100% !important;
  border: none !important;
  padding: 10px 15px !important;
}

.certificate-btn.qr-code button.shipment-status {
  background: #f08dc9 !important;
}

.certificate-btn.qr-code button.shipment-gen {
  background: #1259ff !important;
}

.genship-popup tbody tr td,
.genship-popup tbody tr th {
  text-align: center !important;
  font-weight: 700;
}

.qr-product-scan.genship img {
  width: 300px;
  margin-top: 25px;
}

.action-part.genship {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
}

.generate-btns .certificate-btn button {
  padding: 10px 20px;
  width: auto;
}

.generate-btns.authseller .certificate-btn button {
  padding: 10px 20px;
  background: #e9da55;
  border: none !important;
}

.auth-seller-flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
  gap: 60px;
}

.start-end-date.start-end-date-admin.estimated.date-product-admin
  .MuiStack-root {
  justify-content: center !important;
}

.bundle-seller {
  height: 300px;
  overflow-y: scroll;
  padding-right: 30px;
}

.bundle-seller p {
  margin-top: 0px;
}

.bundle-seller p.action-part-cnt,
.action-part-cnt.bundle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  text-align: center;
}

p.pro-id.complet {
  max-width: 800px;
  margin: 0 auto;
  font-size: 20px;
}

.generate-product.process-complete {
  margin-top: 30px;
}

.generate-product.process-complete button.print-lab-first {
  background: #55e9ce !important;
  color: #000 !important;
}

.generate-product.process-complete button.print-lab-sec {
  background: #8de955 !important;
  color: #000 !important;
}

.generate-product.process-complete button.print-lab-third {
  background: #e9da55 !important;
  color: #000 !important;
}

.popBOX.BlockPopup.infopopup.seller-popp {
  height: auto !important;
}

/* .product-allocate-btn{
    display: flex;
    justify-content:center;
    gap: 20px;
   }

   .product-allocate-btn button{
   background-color: #0354CE;
   color: #fff;
   text-transform: capitalize;
   }

   .product-allocate-btn button:hover{
    background-color: #0354CE;
    }

    .product-allocate-btn div#demo-simple-select {
        border: 1px solid;
        padding: 10px 28px 10px 15px !important;
    } */

/* demo */
.popBOX.BlockPopup.infopopup.seller-popp.pppp,
.popBOX.pppp {
  height: 80% !important;
}

/* demo */

.print-label {
  margin-top: 300px;
}

.print-label img {
  height: 300px;
  width: 300px;
}

.add-seller.up-btn {
  display: flex;
  justify-content: center;
}
