.left-crayons {
    /* padding: 10px 40px; */
    /* position: relative;
    height: 0px;
    padding-bottom: 68%; */
    width: 300px;
    margin: 0 auto !important;
}


.load-imgs img {
    width: 45px;
}

.please-load-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 23px;
}


.left-crayons img {
    width: 80%;
    background: #fff;
    border-radius: 15px;
    padding: 5px;
}

.togglebtn.prodetails {
    top: 115px !important;
}

.coin-icon button img {
    width: 40px;
    height: 30px;
}

.coin-icon button:hover {
    background: transparent;
}

.item-summary,
.field-trip {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coin-icon.share {
    display: flex;
}

.coin-icon.share button svg {
    fill: rgb(18, 18, 18);
    font-size: 18px;
}

.coin-icon.share button.share svg {
    fill: rgb(32, 129, 226);
    font-size: 18px;
}

.field-trip {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.field-trip h5 {
    font-size: 16px;
    color: rgb(32, 129, 226);
    text-decoration: none;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.field-trip h5 svg {
    /* background: rgb(32, 129, 226); */
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    padding: 2px;
    fill: rgb(32, 129, 226);
}

.trip {
    text-align: start;
}

.trip h2 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.trip p {
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    color: #2b2b2b;
    height: 24px;
}

.trip span {
    font-size: 20px;
    color: rgb(32, 129, 226);
}

.views {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 29px;
    margin: 24px 0;
}

.views p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgb(18, 18, 18);
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.views h4 {
    color: rgb(18, 18, 18);
    font-size: 14px;
    border: 1px solid #00000036;
    padding: 2px 8px;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 600;
}

.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
}

.expired-notice>span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.expired-notice>p {
    font-size: 1.5rem;
}

.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.75rem;
    padding: 0.5rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
}

.show-counter .countdown {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown>p {
    margin: 0;
    color: rgb(235, 87, 87);
}

.show-counter .countdown>span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
}

.counter h2 {
    font-weight: 400;
    font-size: 16px;
    color: rgb(18, 18, 18);
    margin: 0;
}

.counter .show-counter a {
    border: none;
    padding: 0;
    justify-content: flex-start;
}

.counter {
    text-align: start;
    border: 1px solid #00000036;
    /* padding: 20px; */
    border-radius: 10px 10px 0 0;
    border-bottom: none !important;
}

.counter .show-counter a {
    border: none;
    padding: 0;
    justify-content: flex-start;
    gap: 25px;
}

.counter.price {
    border-radius: 0 0 10px 10px;
    padding: 20px;
    border-bottom: 1px solid #00000036 !important;
}

.counter.price h3 {
    font-weight: 600;
    color: rgb(18, 18, 18);
    margin: 4px 0 6px;
    font-size: 30px;
}

.counter.price span {
    color: rgb(84, 84, 84);
    font-size: 15px;
    font-weight: normal;
}

.two-btn button.buy-now {
    background: #1c62d3e8;
    color: #fff;
    padding: 18px 15px;
    width: 50%;
    border-radius: 12px;
}

.two-btn button.make-offer {
    background: rgba(18, 18, 18, 0.04);
    box-shadow: none;
    color: rgb(18, 18, 18);
    padding: 18px 15px;
    width: 50%;
    border-radius: 12px;
    height: 48px;
    text-transform: capitalize;
    font-weight: 600;
}

.two-btn button svg {
    margin: 0 10px;
}

.two-btn {
    display: flex;
    gap: 30px;
}

.two-btn.under-field {
    justify-content: center;
    margin-top: 50px;
}
.two-btn.under-field.qr-product-detail{
    flex-direction: column;
    gap: 15px !important;
}
.reactive-btn {
    font-style: normal;
    font-weight: 400;
    margin-left: 3px;
    text-align: center !important;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none!important;
    color: #0065ff!important;
}
.product-detail-main-content-page .two-btn.under-field .BuynOW-cart {
    width: auto !important;
    /* background: #1976d2; */
    justify-content: center;

}

.two-btn.under-field .btnBuy {
    width: 200px !important;
}

.two-btn .btnCart {
    width: 50px;
}

.SubPages.prodetails button {
    color: #fff;
    border-radius: 5px;
}

.more-drop-menu {
    top: 40px !important;
    left: -20px !important;
}

.more-drop-menu ul li {
    gap: 15px;
    padding: 8px;
}

.more-drop-menu ul {
    padding: 10px;
}

.leftside {
    /* border: 1px solid #00000036; */
    /* padding: 12px !important; */
    overflow: hidden;
    /* background: transparent !important; */
    /* box-shadow: none !important; */
    /* border-radius: 10px !important; */
    width: 100%;
}

.total-subproduct {
    border: 2px solid #00000036;
    /* overflow: hidden; */
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}

.description,
.para,
.accordion {
    text-align: start;
    border: 1px solid #00000036;
    /* padding: 5px 25px; */
    border-radius: 10px 10px 0 0;
    margin-top: 20px;
}

.para,
.accordion {
    border-radius: 0;
    margin: 0 !important;
    padding-top: 15px !important;
}

.head-title h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 20px;
    line-height: 24px;
}

.descr-para h6 {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    color: rgb(138, 147, 155);
    display: flex;
}

.descr-para span {
    color: rgb(18, 18, 18);
    font-weight: 600;
    font-size: 16px;
    margin-left: 5px;
}

.descr-para p {
    font-size: 14px;
    color: rgba(18, 18, 18, 0.833);
    margin-top: 5px;
    margin-bottom: 0;
    line-height: 24px;
}

div#panel1a-header p.MuiTypography-body1 {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px !important;
    font-weight: 600;
}

.accordion p.MuiTypography-body1 {
    text-align: start;
    font-size: 14px;
    line-height: 24px;
}

.accordion p svg {
    font-size: 20px;
}

.accordion {
    padding: 0 !important;
    border-top: 0;
    border-bottom: 0;
}

.inner-accor {
    background: transparent !important;
    box-shadow: none !important;
}

.flex-detail-outer .flex-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail.detail-items-customer {
    text-align: start;
    border: 1px solid #00000036;
    border-radius: 0 0 10px 10px;
    /* padding-top: 10px; */
}

.detail-items-customer {
    margin-top: 0px;
}

.listing {
    text-align: start;
    border: 1px solid #00000036;
    border-radius: 10px;
    margin-top: 25px !important;
}

.table-accordion {
    padding: 0 !important;
}

.listing table thead {
    border-top: 1px solid #00000036;
    border-bottom: 1px solid #00000036;
}

.listing table thead tr th {
    padding: 5px 16px;
    text-align: left;
    margin-left: 0px;
}

.listing table tbody tr th {
    padding: 22px;
}

.table-buy {
    color: rgb(255, 255, 255);
    background-color: rgb(46, 142, 238);
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
}

.listing table tbody tr td span {
    color: rgb(46, 142, 238);
    ;
}


@media (max-width:1199.98px) {

    .product-detail {
        margin: 0;
    }

    .two-btn {
        flex-direction: column;
    }

    .two-btn button {
        width: 100% !important;
        white-space: nowrap;
    }


    .counter .show-counter a {
        gap: 4px;
        justify-content: space-between;
    }

    .show-counter .countdown>p {
        font-size: 16px;
    }

}






.sale {
    margin: 20px 0;
}

.sale-head {
    border-bottom: 1px solid rgba(18, 18, 18, 0.12);
    font-weight: initial;
    padding: 20px;
}

.show-counter {
    padding: 0px 20px;
}

.product-detail-main-content-page .BuynOW-cart {
    display: flex;
    position: relative;
    width: 50% !important;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    border-radius: 12px;
    height: 48px;
}

.product-detail-main-content-page .BuynOW-cart Button {
    height: 48px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-transform: none;
    font-weight: 600;
}

.product-detail-main-content-page .BuynOW-cart Button svg {
    color: #fff;
    margin: 0;
}

.product-detail-main-content-page .BuynOW-cart Button span {
    margin: 0;
}

.product-detail-main-content-page table.MuiTable-root td {
    text-align: left;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 8px;
    padding-left: 16px;
}

.product-detail-main-content-page table.MuiTable-root th {
    font-size: 16px;
    font-weight: 400;
    color: #545454 !important;
}

.mild-font {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: rgb(18, 18, 18);
}

.counter.price h2 {
    font-weight: 400;
    font-size: 14px;
    color: rgb(84 84 84);
    margin: 0;
}

.support-creator-icon svg {
    fill: rgb(235, 87, 87) !important;
}

.support-creator-icon {
    margin-right: 10px;
}

.support {
    margin-top: 16px;
    display: flex;
}

.support-creator-info {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
}

.support-creator-info span {
    line-height: 16px;
    opacity: 1;
    color: rgb(84, 84, 84);
    font-weight: 600 !important;
}

.descr-para {
    padding: 30px;
}

.para {
    padding-top: 0 !important;
    border-top: 0 !important;
    height: initial;
    max-height: 130px;
    overflow: auto;
}

.descr-para svg {
    padding-left: 8px;
    fill: rgb(32, 129, 226);
}

.collectBtn svg {
    font-size: 20px !important;
    color: #000;
}

.accordion .MuiAccordionSummary-root.Mui-expanded,
.detail-items-customer .MuiAccordionSummary-root.Mui-expanded,
.listing .MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    border-bottom: 1px solid #00000036;
    /* padding: 20px; */
}

.accordion .MuiAccordionSummary-content.Mui-expanded,
.accordion .MuiAccordionSummary-content,
.detail-items-customer .MuiAccordionSummary-content.Mui-expanded,
.detail-items-customer .MuiAccordionSummary-content,
.listing .MuiAccordionSummary-content.Mui-expanded,
.listing .MuiAccordionSummary-content {
    padding: 0;
    margin: 0;
}

.accordion .MuiAccordionSummary-root.Mui-expanded,
.accordion .MuiAccordionSummary-root,
.detail-items-customer .MuiAccordionSummary-root.Mui-expanded,
.detail-items-customer .MuiAccordionSummary-root,
.listing .MuiAccordionSummary-root {
    padding: 20px;
}

.accordion .MuiAccordionDetails-root {
    padding: 20px;
}

.flex-detail label {
    color: rgb(18, 18, 18);
}

.flex-detail a {
    text-decoration: none;
    font-weight: 500;
    color: rgb(32, 129, 226);
    font-weight: 500;
    font-size: 14px;
}

.detail-items-customer .MuiAccordionDetails-root {
    padding: 20px !important;
}

.flex-detail {
    margin-top: 0.5rem;
}

.flex-detail span {
    margin: 0px;
    max-width: 365px;
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    color: rgb(84, 84, 84);
}

.flex-detail-outer .flex-detail .earning .MuiButtonBase-root {
    min-width: 0 !important;
    padding-bottom: 12px;
}

.earning svg {
    font-size: 14px !important;
    fill: #767676;
}

.product-detail-main-content-page table.MuiTable-root td.starting {
    color: #2081e2;
    font-size: 15px !important;
    text-transform: uppercase;
    font-weight: 400 !important;
}

button.table-buy {
    background-color: rgb(46, 141, 238);
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.listing table.MuiTable-root td {
    font-size: 14px;
    font-weight: 600;
}

.listing table.MuiTable-root td.list-Name {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.87);
}

.listing table.MuiTable-root td {
    color: #494949;
    font-weight: 500 !important;
    border: none !important;
}

.product-detail-main-content-page .listing table.MuiTable-root td.buy {
    text-align: right;
}

.product-detail-main-content-page .listing table.MuiTable-root td.event {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgb(18, 18, 18);
    font-size: 15px;
}

.product-scroll .grid-pdt-block-specific {
    padding: 16px;
}

.product-scroll .product-col-block {
    padding-left: 0;
}

.product-scroll .table-accordion {
    overflow-x: scroll;
    display: block;
    width: 100%;
}

.product-scroll .product-item-img img {
    width: 323px;
    min-height: 200px;
    height: 250px;
}

.product-scroll .total-card {
    display: flex;
    gap: 30px;
}

.product-scroll .table-width {
    /* margin-left: 30px; */
    float: left;
    /* width: 100%; */
    /* background: #F5F5F5; */
    padding: 0px 16px;
}

/* .product-item-img img {
    width: 330px;
    min-height: 200px;
} */

.ratingDetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 5px 0;
}

.product-scroll .BuynOW-cart {
    width: 100% !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.grid-pdt-block-specific .cards-items:hover .BuynOW-cart {
    opacity: 1;
}

.product-scroll .BuynOW-cart {
    display: flex;
    gap: 1px;
    position: relative;
    bottom: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
}

.cards-items {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
    transition: box-shadow 0.25s ease-in-out 0s;
    border-radius: 12px;
    overflow: hidden;
    /* height: 300px; */
}

.product-scroll .pdt-name-price h4.pdt-name {
    margin: 0px;
    text-align: left;
    font-size: 14px;
    color: #757272;
    font-weight: 600;
    color: rgb(4, 17, 29);
}

.product-scroll .pdt-name-price {
    padding: 0;
    padding-bottom: 0;
}

.product-scroll .sale {
    font-size: 14px;
    font-weight: 400;
    color: #2b2b2b;
    line-height: 20px;
}

.product-scroll span.pdt-price {
    margin: 10px 0;
}

.ProfileContent .product-col-block {
    padding-top: 0 !important;
}

.product-detail-main-content-page .BuynOW-cart svg {
    color: #fff !important;
}

.product-detail-main-content-page .cards-items .product-item-img img {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}

.product-detail-main-content-page .cards-items:hover .product-item-img img {
    transform: scale(1.1);
    transition: transform 0.4s ease 0s;
    /* height:200px; */
}

.product-detail-main-content-page .listing.activity.product-scroll .MuiAccordionSummary-root {
    border-bottom: 1px solid #00000036;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

}

::-webkit-input-placeholder {
    color: #000 !important;
}

.product-detail-main-content-page #tags-outlined {
    border: 1px solid rgba(18, 18, 18, 0.12);
    border-radius: 20px;
    margin-top: 10px;
    width: 96%;
    padding: 20px 11px;
    padding-right: 11px;
}

.product-detail-main-content-page .MuiAutocomplete-tag {
    margin: 3px;
    max-width: calc(100% - 6px);
    border-radius: 8px !important;
    padding: 2px 16px !important;
    height: 52px !important;
    font-size: 16px !important;
    font-weight: 500px !important;
    padding-left: 0 !important;
}

.product-detail-main-content-page .MuiOutlinedInput-root {
    padding: 16px !important;
}

.product-detail-main-content-page .MuiAutocomplete-inputRoot {
    /* padding-left: 0; */
}

p.item-activity {
    max-height: 332px;
    overflow-x: auto;
    width: 100%;
    overflow-y: auto;
    /* background: #fff !important; */
    border-radius: 9px;
}

.item-activity .MuiPaper-root {
    border-color: rgba(0, 0, 0, 0);
    overflow-y: auto;
    transition: border-color 0.2s ease-in 0s;
}

.item-activity .MuiPaper-root:hover {
    border-color: rgba(0, 0, 0, 0.4);
    transition: none 0s ease 0s;
}

/* width */
p.item-activity::-webkit-scrollbar {
    width: 3px;
}

/* Track */
p.item-activity::-webkit-scrollbar-track {
    background: #a9c6ff !important;
    border-radius: 12px;
    overflow: hidden;
}

/* Handle */
p.item-activity::-webkit-scrollbar-thumb {
    /* background: #080808;  */
    background-color: rgba(0, 0, 0, 0);
}

p.item-activity:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
}

/* Handle on hover */
p.item-activity::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.listing.activity.Item input {
    padding: .75rem !important;
    position: relative;
}

.listing.activity.Item .MuiAutocomplete-endAdornment {
    position: absolute !important;
    right: 30px !important;
    top: 63% !important;
    margin-top: -10px;
    /* margin: auto; */
}

.product-detail-main-content-page .listing.activity.product-scroll .MuiAccordionSummary-root {
    border-bottom: 0 !important;
}

.tags-outlined {
    position: relative;
}

.listing.activity.product-scroll .inner-accor .cards-items:hover .sale {
    display: none;
}

.listing.activity.product-scroll .inner-accor .cards-items .BuynOW-cart {
    position: absolute;
    bottom: 0;
    justify-content: center;
    background-color: #1976d2;
}

.product-scroll .table-width .BuynOW-cart button {
    box-shadow: none !important;
}

.listing.activity.product-scroll .inner-accor .cards-items {
    position: relative;
}

.listing.activity.product-scroll span.pdt-price {
    margin: 10px 0 15px;
}

.listing.activity.product-scroll .table-accordion::-webkit-scrollbar {
    height: 5px !important;
}

/* Track */
.listing.activity.product-scroll .table-accordion::-webkit-scrollbar-track {
    background: #a9c6ff92 !important;
    border-radius: 12px;
    overflow: hidden;
}

/* Handle */
.listing.activity.product-scroll .table-accordion::-webkit-scrollbar-thumb {
    /* background: #080808;  */
    background-color: rgba(0, 0, 0, 0);
}

.listing.activity.product-scroll .table-accordion:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
}

/* Handle on hover */
.listing.activity.product-scroll .table-accordion::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.listing.activity.product-scroll .total-card {
    display: flex;
    gap: 38px;
}

.view-collect a {
    border-radius: 0.75rem;
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: none;
    transition-duration: .2s;
    color: rgb(18, 18, 18);
    background-color: rgba(18, 18, 18, 0.04);
    display: inline-block;
}

.view-collect a:hover {
    background-color: rgba(18, 18, 18, 0.08);
}

.view-collect {
    padding: 0.5rem !important;
    margin-top: 0.5rem !important;
    text-align: center;
    border-top: 1px solid rgba(18, 18, 18, 0.12);
    color: rgb(18, 18, 18);
}

.traits {
    border: 1px solid #00000036;
    border-top: 0;
}

.traits .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #00000036;
}

.trait-card {
    background: rgba(18, 18, 18, 0.04);
    border-radius: 6px;
    padding: 10px;
    text-align: center;
}

a.trait-card-link .trait-card {
    margin-bottom: 10px;
}

.traits .MuiAccordionDetails-root {
    padding: 5px !important;
}

.text-sec {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(84, 84, 84);
    opacity: 1;
}

.trait-card p {
    font-size: 14px;
    color: inherit;
    margin: 0;
    padding: 0.25rem 0;
}

.trait-card p span,
.trait-card .floor {
    color: rgb(84, 84, 84);
    font-size: 14px;
    font-weight: 400;
}

a.trait-card-link.sec .trait-card:nth-child(1),
a.trait-card-link.sec .trait-card:nth-child(2),
a.trait-card-link.sec .trait-card:nth-child(3) {
    margin-bottom: 0 !important;
}

.traits .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    margin: 0;
}

.MuiAccordionSummary-root {
    padding: 20px !important;
}

.ratingDetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@media (max-width:768px) {
    .text-box {
        flex-direction: column;
        width: 100%;
        padding: 0px !important;
    }
    .productdetail-image img {
        width: 82%;
    }
}

@media (max-width:991.98px) {

    .field-desktop-response {
        padding: 0 15px;
    }

    .left-crayons {
        width: 200px !important;
    }

    .trip h2 {
        font-size: 25px !important;
    }

    .field-mobile-response .field-trip h5 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .field-mobile-response .field-trip {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 7px;
    }

    .product-detail {
        padding-top: 0;
    }

    .coin-icon.share button {
        min-width: auto;
    }

    .coin-icon.share {
        display: flex;
        gap: 10px;
    }

    .field-mobile-response .trip h2 {
        line-height: normal;
        font-size: 500;
    }

    .field-mobile-response .trip p,
    .field-mobile-response .trip span {
        font-weight: 400;
        font-size: 16px;
    }

    .field-mobile-response .leftside {
        margin: 20px 0;
        width: 100% !important;
    }

    .description,
    .para,
    .traits,
    .accordion,
    .detail.detail-items-customer {
        display: none;
    }

    .views {
        margin: 0;
        justify-content: center;
    }

    .views,
    .sale,
    .listing,
    .description.sm-responsive,
    .para.sm-responsive,
    .traits.sm-responsive,
    .accordion.sm-responsive,
    .detail.detail-items-customer.sm-responsiv {
        margin: 4px 0 !important;
    }

    .listing.activity.product-scroll {
        margin-top: 0 !important;
    }

    .listing.activity.Item {
        margin-top: 4px;
    }

    .two-btn {
        gap: 12px;
    }

    .product-detail-main-content-page .BuynOW-cart {
        width: 100% !important;
    }

    button.btnBuy {
        width: 90% !important;
    }

    button.btnCart {
        width: 10% !important;
    }

    .counter.price .support-creator-info {
        font-size: 16px !important;
    }

    div#panel1a-header p.MuiTypography-body1 {
        font-size: 15px !important;
    }

    .description.sm-responsive,
    .para.sm-responsive,
    .traits.sm-responsive,
    .accordion.sm-responsive,
    .detail.detail-items-customer.sm-responsive {
        display: block !important;
    }

    .listing ::-webkit-scrollbar {
        height: 5px;
    }

    .product-scroll .table-width {
        padding: 0 !important;
    }

    p.item-activity::-webkit-scrollbar {
        width: 5px;
    }

    .product-scroll .product-item-img img {
        width: 247px !important;
    }

    .cards-items:hover {
        width: 282px !important;
    }

    .left-crayons img {
        width: 100%;

    }
}

@media(min-width:992px) and (max-width:1199.98px) {

    .field-mobile-response .leftside {
        margin: 20px 0;
    }
}

@media(min-width:1200px) and (max-width:1349.98px) {
    .field-mobile-response .leftside {
        margin: 20px 0;
    }
}

.productdetails-logo {
    display: flex;
    justify-content: center;
    margin: 30px 0px;

}

.cancel-div {
    display: flex;
    justify-content: end;
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
    margin-right: -15px !important;

}


.productdetail-image {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    width: 35%;
    margin: 0 auto;
    border-radius: 15px;
}

.productdetail-image img {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
}

.product-id-text {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.product-id-text2 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0px;
}

.text-box input {
    color: #000;
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    border-radius: 10px;
}

.go-red {
    color: red !important;
    margin-top: 5px;
}

.text-box {

    margin: 0 auto;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 0px 100px;
}

.purchase-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}


.purchase-btn button,
.purchase-btn button:hover {
    background-color: #1976d2 !important;
    color: #fff;
}


.product-detail-box:focus-visible {
    outline: 0 !important;
}

.productdetail-image1 img {
    width: 120px;
    height: 120px;
    background: #fff;
    padding: 5px;
    border-radius: 10px;
    object-fit:contain;
}


.productdetail-image1 {
    margin: auto;
    text-align: center;
    /* padding: 30px 0px; */
}

.qr-product-scan-map-div{
    display: flex;
    flex-direction: column !important;
    align-items: center;
}