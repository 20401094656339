.SubPages {
    /* background-color: white !important; */
    padding: 10px;
    margin: 0px 15px;
    margin-top: 100px;
    /* overflow-x: scroll; */
    border-radius: 15px;
}

.SubPages.prodetails {
    margin-top: 40px !important;
}

.SubPages-inner-text {

    display: flex;
    gap: 15px;
    align-items: center;
    overflow-x: scroll;
}


.SubPages button {
    text-transform: capitalize;
    color: #000;
    padding: 10px 14px;
    font-size: 16px;
    border-radius: 10px;

}

.SubPages .btn-1 button {
    background-color: #f0eded;
}

hr {
    margin-top: 15px;
    opacity: 30%;
}

.svg-icon svg,
.svg-icon i {
    margin-left: 10px;
    /* background-color: #f0eded; */
    padding: 5px;
    border-radius: 6px;
}

.svg-icon {
    display: flex;
    justify-content: center;
}

.display-set {
    align-items: center;


}

.display-set1 div {
    min-width: -webkit-fill-available;
}

.select-div div {
    border: 1px solid;
    border-radius: 10px;
}

.search-box-filter {
    border: 1px solid;
    border-radius: 10px !important;
    margin: 0px 8px;
}
.priceList .simple-select-form {
    margin: 0;
}

.priceList div#demo-simple-select {
    border: 1px solid;
    border-radius: 10px !important;

}

.tiles-block {
    display: flex !important;
    gap: 38px;
    background-color: hsl(207.27deg 100% 91.37% / 47%);
    padding: 3px;
    border-radius: 12px;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-left: 68px; */
    margin: auto;
}

.titles-tab {
    /* margin-left: 40px; */
    display: flex;
    justify-content: center;
}

.tile-design svg:hover {
    background-color: #fff;
    transition: 0.3s;
}

.tile-design svg {
    padding: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.body-text-div {
    padding: 30px 15px;
    /* margin-left: 30px; */
    font-weight: 600;
    font-size: 25px;
}


.body-text-div2 {
    border: 1px solid;
    margin: 10px 50px;
    border-radius: 15px;
    padding: 100px 0px;
    text-align: center;
}


.No-items {
    font-size: 20px;
    font-weight: 700;
}

.user-dashboard-btn button {
    background-color: rgb(46 141 238);
    margin-top: 30px;
    color: #fff;
}

.user-dashboard-btn button:hover {
    background-color: rgb(46 141 238);
}



@media (max-width:468px) {
    .body-text-div2 {
        margin: 0px 10px;
    }
}


.product-allocate-btn {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.product-allocate-btn button {
    background-color: #0354CE;
    color: #fff;
    text-transform: capitalize;
}

.product-allocate-btn button:hover {
    background-color: #0354CE;
}

.product-allocate-btn div#demo-simple-select {
    border: 1px solid;
    padding: 10px 28px 10px 15px !important;
}

/* .card-btn button {
    padding: 15px;
} */

.card-btn img {
    height: 300px;
    width: -webkit-fill-available;
}

.tooltip-box {
    border-radius: 10px;
}

/* .padding-null{
    padding: 0px !important;
} */


/* .card-btn :hover{
        transform: scale(1.10, 1.10);
    
            } */


.card-btn {
    display: flex;
    justify-content: center;
    margin: 15px 10px;
}

.card-btn1 {
    border-radius: 15px !important;
}

.card-inner-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.card-inner-text span {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px;
    /* width: 100%; */
}

.card-inner-text div {
    font-size: 14px;
    padding: 2px 10px;
    border: 1px solid;
    width: fit-content;
    border-radius: 5px;
    font-weight: 600;
}

.card-inner-text p {
    font-size: 13px;
    opacity: 70%;
    margin: 0px;
}

/* .user-dashboard-tab .MuiTabs-flexContainer {
width: fit-content;
background: #ffffff61;
border-radius: 20px;
}


.user-dashboard-tab button{
min-width: 20px !important;
}

.user-dashboard-tab button.Mui-selected {
    background-color: #fff;
    border-radius: 15px;
    color: #000;
} */


.tile-design.active-tiles {
    border-radius: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px);
    background-color: rgb(255, 255, 255);
}

.card-grid {
    display: contents !important;
}

.card-buynow button {
    margin-top: 10px;
    padding: 5px;
    background: rgb(53, 156, 246);
    color: #fff;
    width: 100%;
    display: none !important;
}

/* .card-buynow button:hover{
    background: rgb(53, 156, 246);
} */
/* 
.card-btn:hover .card-inner-text1{
    display: none !important;
} */
/* 
.card-btn:hover .card-buynow button{
    display: block !important;
    transition: 0.3ms;
 } */



.card-buynow {
    display: flex;
    justify-content: center;
}


.collections-img img {
    width: 60px;
    height: 60px;
    /* border-radius: 10px; */
}


.Current-Price-Item-main-box1 img {
    width: 200px;
    height: 200px;
}

.collections-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.collections-img span {
    font-size: 16px;
    font-weight: 500;
}

.Current-Price-Item-main-box1 {
    width: 100%;
    display: flex;
}

.tooltip-box table {
    background: #fff;
}

a {
    text-decoration: none !important;
    color: #000;
}

.security-pswd {
    display: flex;
    justify-content: center;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 50px 0px;
}

.paswd-mid-body {
    width: 517.717px;
    border-radius: 23.821px;
    background: #DFE5F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.pasword-change-body {
    align-items: center !important;
    display: flex;
    flex-direction: column;

}

.old-paswd {
    width: 335.087px;
    height: 49.231px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.188%;
    /* 26.438px */
    padding-top: 30px;

}


.submit-paswd {
    justify-content: center !important;
    border: 2px solid rgb(52, 237, 52) !important;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 2.3508px 0px 0px #FFF inset, 2.3508px 9.40321px 28.20962px 0px rgba(0, 0, 0, 0.16);
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: capitalize;
}

.paswd-change-link {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
    padding-bottom: 20px;
}

.paswd-success-body {
    width: 521.6px;
    height: 433.6px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #DFE5F4;
}

.like-icon {
    display: flex;
    justify-content: center;
}

.paswd-success-text {
    padding: 0px 50px;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 146.484%;
    /* 29.297px */
    display: flex;
    justify-content: center;
}


.dashbody {
    overflow: hidden;
}

.dashbody .notFound {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 300px 0;
}

@media (max-width:767.98px) {

    .body-text-div {
        padding: 20px 15px;
        margin-left: 0;
        text-align: center;
    }

    .search-box-filter {
        margin: 0 !important;
    }

    .tiles-block {
        gap: 35px;
        /* padding: 15px 0 0 5px; */
        margin-left: 0;
        margin: auto;
    }

    .display-set {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .priceList .MuiFormControl-root {
        width: 100%;
    }

    .search-box-filter {
        width: auto !important;
    }

}

@media (min-width:768px) and (max-width: 991.98px) {
    .priceList .MuiFormControl-root {
        width: 90%;
    }


}

@media (min-width:900px) and (max-width: 1199.98px) {
    .SubPages-inner-text .MuiGrid-root {
        max-width: max-content !important;
    }

    .search-bar {
        max-width: none !important;
    }
}


@media (max-width:991.98px) {
    .dashbody .notFound {
        padding: 250px 0;
    }

    /* .tiles-block {
        margin-top: 20px;
        margin-left: 0;
    } */
}

@media (min-width:992px) and (max-width: 1199.98px) {
    .dashbody .notFound {
        padding: 200px 0;
    }
}