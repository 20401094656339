.premium-brands {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.premium-brands .communicate {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.premium-brands .active {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 30px 0;
}

.premium-brands img {
    width: 100%;
    height: 100%;
}

.premium-brands ul.MuiList-root.MuiList-padding {
    border-radius: 9.651px 9.651px 0px 0px;
    background: #DFE5F4;
    margin-top: 30px;
    max-width: 499px;
    height: 500px;
    overflow-y: scroll;
}

.premium-brands ul.MuiList-root.MuiList-padding li span {
    color: #000;
    text-align: left;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.premium-brands ul.MuiList-root.MuiList-padding li p {
    color: #9C9C9C;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.premium-brands ul.MuiList-root.MuiList-padding li .caption span {
    color: #4E4949;
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    text-transform: capitalize;
}

.premium-brands li.divider-border {
    border: 1px solid #000;
    /* color: #000;
    height: 1px; */
}

.premium-brands .MuiAvatar-circular {
    width: 66px;
    height: 66px;
}

.premium-brands li .content {
    margin-left: 35px;
}

.chat-tab {
    display: none;
}

@media(max-width:767.98px) {
    .contact-profile {
        align-items: center;
        display: flex;
        overflow-x: scroll;
        width: 300px;
    }

    .dashboard-page.premium-brand-chat {
        overflow: hidden;
    }

    .premium-brands {
        margin-top: 90px;
    }

    .premium-brands .communicate {
        font-size: 28px;
    }

    .premium-brands ul.MuiList-root.MuiList-padding {
        max-width: 360px !important;
        height: 400px;
        overflow-y: scroll;
    }

    .premium-brands li .content {
        margin-left: 20px !important;
    }
}





/* priya */

.profile-name-dp {
    display: flex;
    align-items: center;
    gap: 15px;
}

.premimum-company-name {
    margin-bottom: 15px;
}

.premimum-company-name p {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
}

.warrenty-body.premimum-body {
    width: 494px;
    padding: 30px;
}

.heading-direct-chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #0077FE;
}

.text-day {
    text-align: center;
}

.text-day p {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

.send-txt {
    max-width: 69%;
    border-radius: 10.726px;
    border: 2.145px solid #0070F0;
    background: rgba(0, 112, 240, 0.50);
    padding: 10px;
    position: relative;
    left: 25%;
    margin-bottom: 20px;
}

.photo-cam {
    line-height: 1;
}



.receive-txt {
    max-width: 69%;
    border-radius: 10.726px;
    border: 2.145px solid #0070F0;
    background: rgba(175, 175, 175, 0.50);
    margin-bottom: 20px;
    padding: 10px;
}

.receive-txt1 {

    margin: 0;

}

.receive-txt1 p {
    margin: 0;

}

.send-txt1 p {
    margin: 0;

}

.send-txt1 {
    position: relative;
    left: 25%;
    margin: 0;
    width: fit-content;


}

.send-txt p, .send-txt1 p {
    word-break: break-all;
}

.txt-block {
    display: flex;
    align-items: center;
    gap: 20px;
}

.chat-txt-area {
    display: flex;
    justify-content: center;
    background: #F6F6F6;
    /* box-shadow: 0px -0.65736px 0px 0px #A6A6AA; */
    width: 87%;
    position: relative;
    left: auto !important;
    position: relative;
    margin: 0 auto;
    bottom: 0;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.extented-warrenty.msg {
    margin-top: 0;
}

.warrenty-body.premimum-body {
    border-radius: 5px 5px 0 0;
}

.txt-field input {
    border-radius: 30px;
    border: 0.996px solid #8E8E93 !important;
    /* background: #ffffff00 !important; */
    font-size: 16px;
    font-weight: 500;
    margin-top: 9px;
    padding: 10px;
    width: 30ch;
    color: #000000;
}

.txt-block svg {
    fill: #0070F0;
    font-size: 27px !important;
}

.txt-field input:hover {
    border: 0.996px solid #8E8E93;
}

.profile-heading-name h1 {
    text-align: center;
    font-size: 40px;
}

.premimum-company-name span {
    color: #9C9C9C;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.chat-box-scroll {
    height: 400px;
    overflow-y: scroll;
}

.txt-block {
    position: relative;
    width: 100% !important;
    padding: 10px;
}

.txt-field .MuiFormControl-root,
.txt-field .MuiFormControl-root input,
.txt-field .MuiFormControl-root fieldset,
.txt-block .txt-field,
.txt-block .txt-field .msg-input:hover {
    width: 100% !important;
}

.txt-block .txt-field .msg-input:hover,
.txt-block .txt-field .msg-input,
.txt-field .MuiFormControl-root input {
    margin: 0 !important;
}

.photo-cam {
    position: absolute;
    right: 4px;
    bottom: 0px;
}

.txt-block button {
    top: 0px
}

.photo-cam .MuiSpeedDial-root {
    position: relative;
}

@media(max-width:374.98px) {
    .chat-txt-area {
        width: 100% !important;
        bottom: 8% !important;
        padding-bottom: 16px !important;
    }
}

@media(max-width:767.98px) {
    .chat-txt-area {
        position: relative;
        margin: auto;
        width: 95%;
        /* bottom: 10%; */
        padding-bottom: 16px;
    }

    .warrenty-body.premimum-body {
        width: 90% !important;
        margin: auto;
    }

    .txt-field input {
        width: 100% !important;
    }

    .chat-box-scroll {
        height: 300px;
        overflow-y: scroll;
    }

    .warrenty-body.premimum-body {
        padding: 30px 13px;
    }

    .extented-warrenty.msg {
        flex-direction: row;
        padding: 0 2px !important;
    }

    .whole-notify-inbox {
        /* width: 250px; */
        padding: 10px !important;
        margin-left: -10px;
    }

    .close-menu-message {
        margin-top: -8px;
        margin-left: 0px;
    }

    .send-txt {
        left: 15%;
    }
}

@media(max-width:991.98px) {
    .msg-pop {
        flex-direction: column;
        width: 330px;
    }

    .heading-direct-chat {
        flex-direction: column;
        padding-bottom: 15px;
    }

    .whole-notify-inbox-outer .MuiTabs-root.MuiTabs-vertical {
        width: auto;
    }

    .whole-notify-inbox-outer.MuiModal-root.css-79ws1d-MuiModal-root .MuiBox-root {
        width: 300px;
    }
}

@media(min-width:768px) and (max-width:991.98px) {
    .chat-txt-area {
        left: auto !important;
        position: relative;
        margin: auto;
    }

    .chat-txt-area {
        position: relative;
        margin: auto;
        width: 91%;
        /* bottom: 10%; */
        padding-bottom: 16px;
    }
}

@media(min-width:992px) and (max-width:1199.98px) {
    .chat-txt-area {
        left: auto !important;
        position: relative;
        margin: auto;
        width: 89%;
    }
}

/* priya */


.close-menu-message {
    margin-top: -30px;
    margin-left: -30px;
}

.close-menu-message img {
    width: 40px;
    height: 40px;
}

.photo-cam .MuiSpeedDial-directionUp button,
.photo-cam .MuiSpeedDial-directionUp button:hover {
    background: #fff !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    height: 40px;
    width: 40px;
    top: 3px;
    left: 8px;
}

.photo-cam .MuiSpeedDial-directionUp button svg {
    font-size: 24px !important;
}