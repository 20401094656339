.CreateItem {
    border: none !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
    max-width: 800px;
    margin: auto;
}

.Create-NFT-page-main .modelTypo {
    color: rgb(18, 18, 18);
    font-size: 16px;
    font-weight: 600;
}

.Create-NFT-page-main .containWidth {
    display: inline !important;
}

.textField {
    width: 100%;
}

.detail .field input {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    height: 54px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.Create-NFT-page-main .detail fieldset,
.Create-NFT-page-main .fieldset {
    border-radius: 6.458px !important;
    border: 1.615px solid #FFF !important;
    opacity: 0.6 !important;
}

.collection.popup .popBOX {
    width: 800px !important;
    padding: 50px 44px 50px 44px !important;
    background: #faf6f6;
}

::-webkit-textField-placeholder {
    /* Chrome */
    color: #000 !important;
}

.Create-NFT-page-main .detail {
    margin-top: 24px;
}

.Create-NFT-page-main .textArea {
    width: 100%;
    border-radius: 10px;
    font-size: 16px !important;
    padding: 20px !important;
    border-color: rgb(226 226 226) !important;
    height: 82px;
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    height: 54px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 94%;
}

.Create-NFT-page-main .modelHead {
    margin-bottom: 4px !important;
    display: flex;
    align-items: center;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #000;
}

.Create-NFT-page-main .para {
    margin-bottom: 20px !important;
    max-width: 650px;
    font-size: 14px;
    color: rgb(84, 84, 84) !important;
    line-height: 1.5;
    display: flex;
    align-items: center;
    padding: 0px !important;
    border: none;
}

.Create-NFT-page-main .Avatar {
    width: 300px;
    height: 295px;
}

.Create-NFT-page-main .para.description {
    display: inherit !important;
}

.Create-NFT-page-main .para span {
    color: #5a8de7;
}

.Create-NFT-page-main .alert span {
    color: red;
}

.Create-NFT-page-main textarea.textArea:focus-visible {
    border: 1px solid rgb(84, 84, 84) !important;
}

.Create-NFT-page-main .alert {
    font-weight: 500;
    font-size: 12px;
    color: rgb(84, 84, 84);
    margin-bottom: 6px;
}

.Create-NFT-page-main .media {
    color: rgb(18, 18, 18);
    font-size: 17px;
    font-weight: 600;
}

.Create-NFT-page-main .fileType {
    margin: 0px;
    color: rgb(84, 84, 84);
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 30px;
}

.Create-NFT-page-main .createNewItem {
    padding: 50px;
    border-radius: 16.667px 16.667px 0 0 !important;
    border: 0.986px solid rgba(203, 239, 255, 0.16) !important;
    background: rgb(239 248 253) !important;
    -webkit-backdrop-filter: blur(98.61111450195312px) !important;
    backdrop-filter: blur(98.61111450195312px) !important;
    margin: 50px auto !important;
}

.dropdown {
    width: 100% !important;
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    height: 54px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    margin: 0 !important;
}

.Create-NFT-page-main .detail.description {
    padding: 0px;
    border: none;
}

.AssetForm--submit {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}

.detail .field fieldset {
    border: none;
}

.detail .field {
    margin-bottom: 20px;
}

.dropdown.detail fieldset {
    border: none;
}

.AssetForm--action {
    display: inline-block;
    margin-top: 24px;
}

.collection.popup .butOutLayer {
    background: rgb(32, 129, 226);
    width: -moz-fit-content;
    width: fit-content;
    border: 2.259px solid rgb(32, 129, 226);
    margin-top: 20px !important;
}

.assetForm_submit,
.collection.popup BUTTON.apply {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 12px;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    border: none;
    padding: 17px 24px;
    font-size: 16px;
    background: linear-gradient(134deg, rgb(32, 129, 226) -26.79%, #8B8B8B 230.75%);
    color: rgb(255, 255, 255);
}

.collectBtn svg path {
    fill: #000 !important;
}

/* .collectBtn svg,
svg {
    font-size: 20px !important;
    width: 100%;
} */

.collection.popup .popBOX .edit-image-btn {
    bottom: 0px;
}

.collection.popup .popBOX .edit-image-btn {
    bottom: 0px;
}

.collection.popup .popBOX .profile-image-outer {
    border-radius: 20px;
}

.collection.popup .popBOX .profile-image.profile-image-design-block {
    margin-top: 0px;
}

/* imageupload */

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.Create-NFT-page .profile-image-outer img {
    height: 220px;
    width: 300px;
}

.Create-NFT-page .upload-image-block {
    width: 300px;
    height: 220px;
}

.Create-NFT-page .createNewItem {
    text-align: left;
}

.menu.collection-create-pop-up-close {
    text-align: right;
}

.menu.collection-create-pop-up-close img {
    width: 30px;
}

.flex-item-modal h2 {
    margin: 0px;
    font-size: 30px;
}

.flex-item-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

div#upload-when-file-in,
#upload-when-file-in-collection {
    height: 100%;
    background: #ccccccb0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    bottom: 0px;
}

.Create-NFT-page.Create-NFT-page-main .profile-image.profile-image-design-block {
    margin-top: 0px;
}

.Create-NFT-page.Create-NFT-page-main .edit-image-btn {
    bottom: 0px;
}

div#upload-when-file-in svg,
#upload-when-file-in-collection svg {
    color: #000;
    font-size: 45px !important;
}

.profile-image-outer:hover #upload-when-file-in,
.profile-image-outer:hover #upload-when-file-in-collection {
    transform: scale(1);
    cursor: pointer;
}

.Create-NFT-page.Create-NFT-page-main .profile-image-outer {
    border-radius: 20px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


/* imageupload */

@media(max-width: 991.98px) {

    .CreateItem {
        width: auto !important;
        margin: auto;
        padding: 9px !important;
    }

    .createNewItem {
        padding: 15px !important;
    }

    .Avatar label {
        line-height: 150px;
    }

    .Avatar {
        width: 10% !important;
        height: 10% !important;
    }

    .Create-NFT-page-main .textArea {
        width: 77%;
    }

    .collection.popup .popBOX {
        width: 80% !important;
        padding: 20px !important;
        border-radius: 20px;
        border: none;
    }

    .Create-NFT-page .upload-image-block {
        height: 220px;
        width: 200px;
    }

    .Create-NFT-page .profile-image-outer img {
        height: 220px;
        width: 200px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .collection.popup .popBOX {
        width: 660px !important;
    }
}