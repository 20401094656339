.TrabiliDadMainPage .start-end-date .MuiInputBase-root {
    width: 100%;
}

.TrabiliDadMainPage .start-end-date input {
    background: #fff;
    border: 1px solid grey;
    height: 10px;
}

.traceability-body {
    position: relative;
    z-index: 0;
}

.filter-colors {
    color: #000 !important;
    font-size: 16.565px !important;
    font-weight: 400 !important;
    border-radius: 4px;
    margin-left: 30px !important;
    text-transform: capitalize !important;
    width: auto;
    height: 53px;
}

.TrabiliDadMainPage .filterBtn {
    width: auto !important;
}

.TrabiliDadMainPage .filter-btn .filterBtn {
    width: auto !important;
    font-size: 0.875rem !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #FFF;
    font-size: 20.565px;
    font-weight: 400;
    line-height: normal;
}

.TrabiliDadMainPage .icon-space {
    display: flex;
    gap: 10%;
    justify-content: center;
    align-items: center;

}

/*Edited by sankar*/
img {
    cursor: pointer;
}

.TrabiliDadMainPage .collectBtn svg,
.TrabiliDadMainPage svg {
    font-size: 24px !important;
}

.TrabiliDadMainPage .filter-btn button.filterBtn {
    padding: 8px 24px;
    gap: 4px;
    font-size: 18px !important;
}

/* .TrabiliDadMainPage {
    background-image: url('../../../img/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */

.TrabiliDadMainPage .containerBody .innerBody {
    background: transparent;
    margin: auto;
    padding: 60px 30px;
    margin-top: 60px;
}

.TrabiliDadMainPage thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root,
.TrabiliDadMainPage tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
    white-space: break-spaces !important;
}

.TrabiliDadMainPage div#chart {
    border-radius: 20px;
    background: var(--neutral-colors-white, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    margin-top: 30px;
}

.TrabiliDadMainPage .filter-btn button {
    padding: 8px 24px;
    gap: 4px;
}

.TrabiliDadMainPage .caption-top {
    color: #3392ff;
}

.TrabiliDadMainPage .filter-btn {
    margin-top: 0;
}

.TrabiliDadMainPage .leftFirstButton {
    display: flex;
    gap: 31%;
    align-items: center;
}
.leftFirstButton.two-btnn {
    margin-left: 55px;
}   

.TrabiliDadMainPage .filter-color svg {
    color: #1976d2;
}

.TrabiliDadMainPage .start-end-date.calender-date {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 66px;
}

.TrabiliDadMainPage .pagination-numb {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
}

.TrabiliDadMainPage .MuiGrid-root.MuiGrid-container.containerBody.css-11lq3yg-MuiGrid-root {
    max-width: 77% !important;
    display: block;
    margin: 48px auto;
}

.TrabiliDadMainPage .invoiceTitle {
    color: #000;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 100%;
    text-transform: capitalize;

}

.TrabiliDadMainPage .topTitle {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.TrabiliDadMainPage .wholeCointainWidth .Title {
    text-align: center;
    margin-top: 10%;
}

.TrabiliDadMainPage .Title p {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
}

.TrabiliDadMainPage .monthly-chart,
.TrabiliDadMainPage .graphLine {
    width: 85% !important;
    margin: auto;
    margin-top: 60px;
}

.TrabiliDadMainPage.TrabiliDad2 .monthly-chart,
.TrabiliDadMainPage.TrabiliDad2 .graphLine {
    width: 96% !important;
    margin: auto;
    margin-top: 60px;
}

.TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
    margin-top: 70px !important;
    max-width: 100%;
    margin: auto;
}

.TrabiliDadMainPage.TrabiliDad2 .dashboargraph-inner-mid {
    display: flex;
    gap: 3%;

}

.TrabiliDadMainPage .dashboargraph-inner-mid-left h5 {
    text-transform: capitalize;
}

.TrabiliDadMainPage .Title p {
    text-transform: capitalize;
    color: var(--neutral-colors-400, #9291A5);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.222px;
}

.TrabiliDadMainPage .pagination-numb ul {
    background: #fff;
}

.TrabiliDadMainPage .BtmBtn {
    display: flex;
    justify-content: space-between;
}

.TrabiliDadMainPage .InvoicePaidTable {
    margin-top: 70px;
}

.TrabiliDadMainPage .invoiceTableRow {
    border: 1px solid #0070f0;
    border-radius: 15px 15px 0px 0px !important;
}

.TrabiliDadMainPage .head-part-table {
    text-transform: capitalize !important;
    text-align: center !important;
}

.TrabiliDadMainPage .table-value {
    text-transform: capitalize !important;

}

.TrabiliDadMainPage th.MuiTableCell-root.MuiTableCell-body,
td.MuiTableCell-root.MuiTableCell-body {
    white-space: break-spaces !important;
    text-align: center;
}

.TrabiliDadMainPage .checkBoxClr svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium path {
    fill: #0070f0 !important;
}

.TrabiliDadMainPage .systemFile {
    width: 25px;
    height: 25px;
}

.TrabiliDadMainPage .head-part-table {
    text-transform: capitalize;
}

.TrabiliDadMainPage .invoiceTableBody {
    border-radius: 15px;
    background: #FFF !important;
}

.TrabiliDadMainPage Button.export {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 16px;
    width: fit-content;
}

.TrabiliDadMainPage .css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
    background-color: #fff !important;
}

.TrabiliDadMainPage .sub-total-direct {
    border-bottom: none !important;
}

.TrabiliDadMainPage .css-1wvnaxz-MuiTableCell-root.MuiTableCell-head {
    color: #000;
    background: #AEC0EC !important;
    text-align: center;
    white-space: nowrap;
}

.TrabiliDadMainPage .invoiceTableRow {
    border: 1px solid #AEC0EC;
    border-radius: 15px 15px 0px 0px !important;
}

.TrabiliDadMainPage .css-132a6sb-MuiTableCell-root {
    border-bottom: 0 !important;
    background-color: #fff !important;
}

.TrabiliDadMainPage tr .css-132a6sb-MuiTableCell-root.MuiTableCell-body {
    font-size: 14px;
    text-align: center !important;
}

.dashboargraph-inner-mid-right1 iframe {
    width: 770px;
    height: 680px;
}

.dashboargraph-inner-mid-right2 iframe {
    width: 400px;
    height: 680px;
}

.wallet-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media all and (max-width: 467.98px) {
    /*Edited by sankar*/

    .adminTraceability-main-page .select-a-product-head,
    .adminTraceability-main-page .select-a-product-location {
        padding: 0 20px !important;
        padding-top: 50px !important;
    }

    .radio-div {
        display: none !important;
    }

    .wallet3-cont.search-div {
        width: 100% !important;
    }

}

.adminTraceability-main-page .select-a-product-head,
.adminTraceability-main-page .select-a-product-location {
    padding: 0 30px;
    padding-top: 54px;
}

.adminTraceability-main-page .graphLine,
.TrabiliDad2.adminTraceability-main-page .graphLine {
    width: 100% !important;
    margin-top: 0px;
}

.adminTraceability-main-page .InvoicePaidTable {
    margin-top: 0px;
}

div#iframe-map-1 iframe,
div#iframe-map-2 iframe {
    width: 100%;
    height: 400px;
}

div#iframe-map-1,
div#iframe-map-2 {
    padding: 5px;
}

.TrabiliDadMainPage.TrabiliDad2.adminTraceability-main-page .InvoicePaidTable {
    margin-top: 0px !important;
}

@media(max-width: 767px) {
    .TrabiliDadMainPage .MuiGrid-root.MuiGrid-container.containerBody.css-11lq3yg-MuiGrid-root {
        max-width: 90% !important;
    }

    p.wallet {
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }

    .TrabiliDadMainPage .containerBody .innerBody {
        margin: auto;
        padding: 31px 30px;
    }

    .TrabiliDadMainPage .leftFirstButton {
        flex-direction: column;
        padding-top: 6  0px !important;
    }
    .leftFirstButton.two-btnn {
        margin-left: 76px;
        margin-top: 15px;
    }
    .leftFirstButton.two-btnn .filter-colors{
        margin-left: 0 !important;
    }
    .TrabiliDadMainPage .topTitle {
        font-size: 20px;
        margin-top: 20px;
    }

    .TrabiliDadMainPage .monthly-chart,
    .TrabiliDadMainPage .graphLine {
        width: 100% !important;
        margin: auto;
        margin-top: 45px;
    }

    .TrabiliDadMainPage .InvoicePaidTable {
        margin-top: 35px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 100%;
        height: 500px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .dashboargraph-inner-mid {
        display: flex;
        flex-direction: column;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        margin-top: 30px !important;
        max-width: 100%;
    }

    .wallet-page-btn {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .metamask-logo img,
    .avatar-profile .MuiAvatar-root.MuiAvatar-circular {
        width: 35px !important;
        height: 35px !important;
    }

    .select .MuiFormControl-root {
        min-width: 100px;
        margin: 0px !important;
    }

    .select-div {
        padding: 2px !important;
    }

    /* .adminTraceability-main-page .select-a-product-head,
    .adminTraceability-main-page .select-a-product-location {
        padding: 20px 20px !important;
    } */

    .wallet-tab-outer {
        width: 100% !important;
    }

    .wallet-trans-card img {
        width: 90% !important;
        height: 100% !important;
    }

    .asset-img img {
        width: 90% !important;
        height: 100% !important;
    }

    .select-amount-div {
        font-size: 20px !important;
        align-items: center !important;
        flex-direction: column !important;
    }

    .payment-success-text h5 {
        font-size: 20px !important;
    }

    .payment-success-text p {
        font-size: 16px !important;
    }

    .payment-success-text div {
        font-size: 16px !important;
    }

    .like-btn img {
        width: 80% !important;
        height: 100% !important;
    }

    .input-form input,
    .input-form2 input {
        width: -webkit-fill-available;
    }

    .input-form2 span {
        font-size: unset !important;
    }

    .select-amount2,
    .select-amount2 span {
        font-size: 18px !important;
    }

    .wallet3-cont .MuiGrid-root.MuiGrid-item {
        padding: 5px 0px !important;
    }

    .wallet3-cont {
        width: 55% !important;
    }
    .leaflet-container {
        margin-top: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .TrabiliDadMainPage .leftFirstButton {
        display: flex;
        gap: 17%;
        align-items: center;
    }

    .TrabiliDadMainPage .monthly-chart,
    .TrabiliDadMainPage .graphLine {
        width: 100% !important;
        margin: auto;
        margin-top: 60px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .dashboargraph-inner-mid {
        display: flex;
        flex-direction: column;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        margin-top: 30px !important;
        max-width: 100%;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 100%;
        height: 500px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .TrabiliDadMainPage.TrabiliDad2 .dashboargraph-inner-mid {
        display: flex;
        flex-direction: column;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 100%;
        height: 500px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        max-width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1349.98px) {
    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 600px;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 300px;
        height: 500px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        max-width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1349.98px) {
    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 600px;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 300px;
        height: 500px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        max-width: 100%;
    }
}

@media (min-width: 1350px) and (max-width: 1440px) {
    .TrabiliDadMainPage .dashboargraph-inner-mid-right1 iframe {
        width: 800px;
        height: 500px;
        margin-bottom: 30px;
    }

    .TrabiliDadMainPage .dashboargraph-inner-mid-right2 iframe {
        width: 300px;
        height: 500px;
    }

    .TrabiliDadMainPage.TrabiliDad2 .InvoicePaidTable {
        max-width: 100%;
    }
}

.leaflet-container {
    height: 600px;
    z-index: 1;
    margin-top: 30px;
}

.tra-img img {
    width: 60px;
    border-radius: 10px;
    background: #e1e1e175;
    padding: 5px;
}


/*Wallet*/

.head-banner {
    background-color: #f2f4f6cc;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accountsection-1 {
    /* background-color: #fff; */
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #0000002d;
    word-break: break-all;
    line-height: 20px;
}

.accountsection-2 {
    /* background-color: #fff; */
    padding: 10px;
    text-align: center;
    padding-bottom: 150px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.accountsection-2 .text {
    font-size: 25px;
    font-weight: 600;
    word-break: break-all;
    line-height: 30px;
}

.accountsection-2 p,
.tabsection-1 p {
    font-size: 16px;
    font-weight: 400;
    margin: 2px;
}

.wallet-page .MuiGrid-root.MuiGrid-item {
    margin: 0 auto !important;
    background-color: #fff;
}

.wallet-page {
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
}


.wallet-whole-div {
    background: #fff;
}


.metamask-logo img {
    width: 70px;
    height: 65px;
}

.select .MuiFormControl-root {
    border-radius: 20px;
    border: 1px solid #00000036;
}

.select ::before,
.select ::after {
    border-bottom: none !important;
}


.avatar-profile .MuiAvatar-root.MuiAvatar-circular {
    border: 3px solid #3392ff;
}

.radio-div label {
    margin-right: 0px !important;
}

.wallet-page svg {
    font-size: 20px !important;
}

.radio-div span.MuiButtonBase-root {
    padding: 4px !important;
}

.account-text {
    text-align: center;
}

.account-text p,
.tabsection-1 p {
    margin: 15px 3px;
}

/* .accountsection-1::after{
border-bottom: 1px solid #000 !important;
} */


.wallet-tab-inner button {
    background: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
    text-transform: capitalize;
}

.wallet-tab-inner .Mui-selected {
    background: #fff !important;
    color: #1976d2 !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
    text-transform: capitalize;
}


.wallet-tab-inner span.MuiTabs-indicator {
    background-color: #1976d2 !important;
}

.wallet-tab-outer {
    margin: 0 auto;
    width: 50%;
}

.tab-panel .MuiBox-root {
    padding: 10px 0px;
}

.tabsection-1 {
    display: flex;
    border-bottom: 1px solid #0000002b;

}

.tabsection-1 img {
    width: 50px;
    height: 50px;
}

.wallet-page-btn a:first-child {
    background-color: #34b1aa;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 15px;
}

.wallet-page-btn a:last-child {
    background-color: #ffaf01;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 15px;
}

.wallet-page-btn {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
}

/* .wallet-page2 .MuiGrid-root.MuiGrid-item {
   
    } */

.select-amount {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    padding: 15px;
    line-height: normal;
}


.select-amount-div {
    color: #151515;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 25px 0px;
}

.select-amount-div div {
    border-radius: 6.2px;
    border: 3.1px solid #0065FF;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 0px 3.1px 0px 0px #FFF inset, 3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16);
    padding: 10px 30px;
}

.input-form,
.wallet-trans-card,
.wallet2-btn,
.asset-img {
    text-align: center;
    padding: 20px 0px;
}

.input-form input,
.input-form2 input {
    border-radius: 6.458px;
    background: #FFF;
    box-shadow: 4.84375px 4.84375px 11.30208px 0px rgba(156, 156, 156, 0.48) inset, -4.84375px -4.84375px 11.30208px 0px #FFF inset;
    padding: 10px 30px;
}

.wallet-trans-card img {
    width: 490px;
    height: 300px;
}

.wallet2-btn button {
    padding: 5px 15px;
    border-radius: 4.519px;
    border: 2.259px solid #00FF29;
    background: linear-gradient(134deg, #0F6 -26.79%, #8B8B8B 230.75%);
    color: #fff;
}

.asset-img img {
    width: 485px;
    height: 50px;

}

.payment-success-text {
    text-align: center;
}


.payment-success-text h5 {
    color: #151515;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    /* 141.667% */
    letter-spacing: 0.24px;
    padding: 10px 0px;
    margin: 5px;
}


.payment-success-text p {
    color: #151515;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
    /* 188.889% */
    letter-spacing: 0.18px;
    padding: 10px 0px;
}

.payment-success-text div {
    color: #151515;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    /* 170% */
    letter-spacing: 0.2px;
}


.payment-success-text span {
    color: #0070F0;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.2px;
}


.select-amount2 span {
    color: #E10B0B;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
}

.select-amount2 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
}

.input-form2 {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
}


.input-form2 span {
    color: #151515;
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
}

.wallet3-cont {
    width: 70% !important;
}

.wallet3-cont .MuiGrid-root.MuiGrid-item {
    padding: 15px 0px;
}

.like-btn img {
    width: 326px;
    height: 326px;
}

.like-btn {
    text-align: center;
}

.wallet3-description {
    width: 80%;
    margin: auto;
}

.wallet-close-icon {
    text-align: end;
}

.wallet-close-icon img {
    width: 35px;
    height: 35px;
}

.wallet3-cont.search-div{
text-align: center !important;
}


.search-check {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.listThree2 {
    display: flex;
    justify-content: center;
}

.cmn-input-cls2 .MuiFormControl-root {
    align-items: center;
}

.popBOX.adminFilterPopup.adminFilterPopup-postsale {
    height: auto !important;
}

.popBOX.trace .listTwo .cmn-input-cls .MuiInputBase-root {
    width: 250px !important;
}
.popBOX.trace .listTwo {
    flex-direction: column;
    align-items: center;
    gap: 10px !important;
}